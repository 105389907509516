import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getCurrentCompanyWithTheirAIFeatures } from "../../../../../SmartAssistant/Utils";
import IconSmartAssistant from "../../../../Components/IconsComponent/IconSmartAssistant";
import "./styles.scss";
import { setIsOpenAssistant } from "../../../../../SmartAssistant/State/assistantAction";

function RenderInSightAIToggle(props) {
  const { isAssistantOpen } = useSelector((state) => state.smartAssistant);
  const { company } = useSelector((state) => state.userData.manifest);

  const dispatch = useDispatch();
  const handleCloseAssistant = (isOpen) => {
    dispatch(setIsOpenAssistant(isOpen));
  };
  useEffect(() => {
    if (isAssistantOpen) {
      dispatch(setIsOpenAssistant(false));
    }
  }, [company?.id, dispatch]);

  if (getCurrentCompanyWithTheirAIFeatures()?.id && props?.userData?.user?.role !== "Member") {
    return (
      <div
        onClick={() => {
          handleCloseAssistant(!isAssistantOpen);
        }}
      >
        <button type="button" className="btn insight-ai-toggle">
          <IconSmartAssistant size="18" />
          <div>
            Insight
            <span>AI</span>
          </div>
        </button>
      </div>
    );
  }
  return null;
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, null)(RenderInSightAIToggle);
