import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Roles from "../../../../Services/Roles";
import styles from "./StyleSidebarLink.module.scss";

function SidebarLinks({ handleRouteChange, sideBarItems }) {
  const { pathname } = useLocation();

  const isActive = useCallback(
    (curr, menu) => curr || menu?.some((sub) => sub === pathname),
    [pathname]
  );
  const isCurrentLink = useCallback((item) => item.link === pathname, [pathname]);
  const isAuthenticated = (role) => {
    if(!role) return true;     //Every User has access
    if (Array.isArray(role)) {
      return role.every((r) => Roles.authenticateViewAndAction(r));
    }
    return Roles.authenticateViewAndAction(role);
  };
  const renderSettingMenus = () =>
     sideBarItems?.map((item) => {
      if (isAuthenticated(item.role)) {
        return (
          <li key={item.name} className={isCurrentLink(item) ? `${styles.link_disabled}` : ""}>
            <NavLink
              to={item.link}
              isActive={(current) => isActive(current, item.subMenu)}
              activeClassName={`${styles.active_List}`}
              onClick={() => handleRouteChange(item)}
            >
              {item.icon}
              {item.name}
            </NavLink>
          </li>
        );
      }

      return null;
    });

  return (
    <div className={styles.sidebar_Block}>
      <ul className="mb-0">{renderSettingMenus()}</ul>
    </div>
  );
}
SidebarLinks.propTypes = {
  handleRouteChange: PropTypes.func.isRequired,
};
export default SidebarLinks;
