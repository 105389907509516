/* eslint-disable no-console */
import axios from "axios";
import { SAMDOCK_BASE_API_URL } from "../Constants";

const addPersonToCompany = async ({ userId, companyId, token }) => {
  try {
    const response = await axios.post(
      `${SAMDOCK_BASE_API_URL}/organizations/${companyId}/contacts`,
      {
        _id: userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.accessToken;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default addPersonToCompany;
