import IconActive from "../../Common/Components/IconsComponent/IconActive";
import IconAdvancedSetting from "../../Common/Components/IconsComponent/IconAdvancedSetting";
import IconCompany from "../../Common/Components/IconsComponent/IconCompany";
import IconDrivethru from "../../Common/Components/IconsComponent/IconDrivethru";
import IconInactive from "../../Common/Components/IconsComponent/IconInactive";
import IconIntegration from "../../Common/Components/IconsComponent/IconInitegration";
import IconLocation from "../../Common/Components/IconsComponent/IconLocation";
import IconPerson from "../../Common/Components/IconsComponent/IconPerson";
import IconPlansLimit from "../../Common/Components/IconsComponent/IconPlansLimit";
import IconReportSettings from "../../Common/Components/IconsComponent/IconReportSettings";
import IconTime from "../../Common/Components/IconsComponent/IconTime";
import IconTrash from "../../Common/Components/IconsComponent/IconTrash";
import IconUsers from "../../Common/Components/IconsComponent/IconUsers";
import { ADVANCED_SETTINGS, CAMERA_INTEGRATION, COMPANY_DETAILS, EMPLOYEE, JOLT, LOCATION, OPENEYE, REPORT_SETTINGS, SCHEDULE, SSO, STATION, SUBSCRIPTION_DETAILS, ZONE } from "../../Constants";
import { ACCESSDRIVETHRU, ACCESSPERSONSUITE, MANAGEDRIVETHRU, MANAGEPERSONSUITE, SETTINGSMANAGECOMPANYPROFILE, SETTINGSMANAGEINTEGRATIONS, SETTINGSMANAGEPLAN, SETTINGSVIEWEMPLOYEE, SETTINGSVIEWLOCATIONS, SETTINGSVIEWSCHEDULE, WOCAMADVANCECAMERASETTINGS } from "../../Constants/AccessList";

export default function getUserFloatingMenuOptions({ onClick }) {
  return [
    {
      key: "activateUser",
      icon: <IconActive />,
      tooltip: "Activate",
      label: "Activate",
      onClick: () => onClick("Active"),
    },
    {
      key: "deactivateUser",
      icon: <IconInactive />,
      tooltip: "Deactivate",
      label: "Deactivate",
      onClick: () => onClick("Inactive"),
    },
    {
      key: "deleteUser",
      icon: <IconTrash />,
      tooltip: "Delete",
      label: "Delete",
      onClick: () => onClick("Delete"),
    },
  ];
}
export function getLocationFloatingMenuOptions({ onClick }) {
  return [
    {
      key: "deleteUser",
      icon: <IconTrash />,
      tooltip: "Delete",
      label: "Delete",
      onClick: () => onClick("delete"),
    },
  ];
}

export const getSidebarList = () => [
  {
    name: "Users",
    link: EMPLOYEE,
    icon: <IconUsers />,
    role: SETTINGSVIEWEMPLOYEE,
    subMenu: [],
  },
  {
    name: "Locations",
    link: LOCATION,
    icon: <IconLocation />,
    role: SETTINGSVIEWLOCATIONS,
    subMenu: [LOCATION, `${LOCATION}/regions`, `${LOCATION}/cities`],
  },
  {
    name: "Schedules",
    link: SCHEDULE,
    icon: <IconTime />,
    role: SETTINGSVIEWSCHEDULE,
    subMenu: [SCHEDULE, `${SCHEDULE}/shifts`],
  },
  {
    name: "DriveThru",
    link: STATION,
    icon: <IconDrivethru />,
    role: [MANAGEDRIVETHRU, ACCESSDRIVETHRU],
    subMenu: [],
  },
  {
    name: "Report Settings",
    link: REPORT_SETTINGS,
    icon: <IconReportSettings />,
    role: [],
    subMenu: [],
  },
  {
    name: "Person Suite",
    link: ZONE,
    icon: <IconPerson />,
    role: [MANAGEPERSONSUITE, ACCESSPERSONSUITE],
    subMenu: [],
  },
  {
    name: "Integrations",
    link: CAMERA_INTEGRATION,
    icon: <IconIntegration />,
    role: SETTINGSMANAGEINTEGRATIONS,
    // hasAccess: !user?.accountManager,
    subMenu: [CAMERA_INTEGRATION, OPENEYE, JOLT, SSO],
  },
  {
    name: "Company",
    link: COMPANY_DETAILS,
    icon: <IconCompany />,
    role: SETTINGSMANAGECOMPANYPROFILE,
    subMenu: [],
  },
  {
    name: "Plan & Usage",
    link: SUBSCRIPTION_DETAILS,
    icon: <IconPlansLimit />,
    role: SETTINGSMANAGEPLAN,
    subMenu: [],
  },
  {
    name: "Advance",
    link: ADVANCED_SETTINGS,
    icon: <IconAdvancedSetting />,
    role: WOCAMADVANCECAMERASETTINGS,
    subMenu: [],
  },
];
