/**
 * Converts a given number of seconds into a formatted time string.
 *
 * @param {number} seconds - The number of seconds to be converted.
 * @returns {string} The formatted time string.
 */

import moment from "moment";

export function convertSecondsInclFracPart(value) {
  const seconds = parseFloat(value);
  // Parse as float to handle fractional seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds / 60) % 60); // Calculate minutes with fractional part
  const secondsRemaining = Math.round(seconds % 60); // Round the remaining seconds

  if (hours) {
    if (minutes) {
      return `${hours}h ${minutes}m ${secondsRemaining}s`;
    }
    return `${hours}h ${secondsRemaining}s`;
  }

  if (minutes) {
    return `${minutes}m ${secondsRemaining}s`;
  }

  return `${secondsRemaining}s`;
}

export function convertSeconds(seconds) {
  var seconds = parseInt(seconds, 10);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  var seconds = seconds - hours * 3600 - minutes * 60;
  if (hours) {
    if (minutes) {
      return `${hours}h ${minutes}m ${seconds}s`;
    }
    return `${hours}h ${seconds}s`;
  }

  if (minutes) {
    return `${minutes}m ${seconds}s`;
  }

  return `${seconds}s`;
}

export function convertSecondsToHrsMinsSecs(
  seconds,
  displaySeconds = false,
  displayZeroSec = false
) {
  seconds = parseInt(seconds, 10);

  // Calculate hours, minutes, and seconds.
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  // Helper function to format the time units.
  const formatUnit = (value, singular, plural) =>
    value === 1 ? `${value} ${singular}` : `${value} ${plural}`;

  const result = [];

  // If hours, minutes or seconds are present, push the formatted data to the result.
  // Don't show minutes or seconds if hours >= 10 and handle for displaySeconds flag.
  if (hours >= 10) {
    result.push(formatUnit(hours, "hr", "hrs"));
  } else {
    if (hours) result.push(formatUnit(hours, "hr", "hrs"));
    if (minutes) result.push(formatUnit(minutes, "min", "mins"));
    if (displaySeconds && seconds) result.push(formatUnit(seconds, "sec", "secs"));
  }

  if (displaySeconds && !seconds && displayZeroSec) {
    result.push(formatUnit(seconds, "sec", "secs"));
  }

  return result.join(" ");
}

export const formatTimeInHhMm = (totalMinutes) => {
  const duration = moment.duration(totalMinutes, "minutes");
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes()) % 60;

  const momentObj = moment().set({
    hours,
    minutes,
    seconds: 0,
    milliseconds: 0,
  });

  return momentObj.format("HH:mm");
};
export function formatTimeIntoDateObject(time) {
  const dateString = `${new Date().toDateString()} ${time}`;
  return new Date(dateString);
}
export const defaultTimeRange = {
  startTime: new Date(new Date().setHours(0, 0, 0, 0)).toLocaleTimeString(),
  endTime: new Date(new Date().setHours(23, 59, 59, 0)).toLocaleTimeString(),
};

export const formatLocalTimeString = (time) => moment(time, ["h:mm:ss A"]).format("HH:mm:ss");

export const formattedDefaultTimeRange = {
  startTime: formatLocalTimeString(defaultTimeRange.startTime),
  endTime: formatLocalTimeString(defaultTimeRange.endTime),
};
/**
 *
 * @param {string} time
 * @returns string
 */
export const adjustTime = (time) => {
  if (time === "00:00:00" || time.endsWith("59")) {
    return time;
  }
  let [hours, minutes, seconds] = time.split(":").map(Number);

  seconds -= 1;

  if (seconds < 0) {
    seconds = 59;
    minutes -= 1;
  }

  if (minutes < 0) {
    minutes = 59;
    hours -= 1;
  }

  if (hours < 0) {
    hours = 23;
  }

  const formattedTime = [
    String(hours).padStart(2, "0"),
    String(minutes).padStart(2, "0"),
    String(seconds).padStart(2, "0"),
  ].join(":");

  return formattedTime;
};

export const formatTimeDisplay = (time) => {
  if (!time) return "";
  return moment(time, "HH:mm:ss").format("hh:mm A");
};
