class URLService {
  /**
   * Encodes an object of query parameters into a URL-encoded query string.
   * @param {Object} params - The query parameters as key-value pairs.
   * @returns {string} - The encoded query string.
   */
  static encodeQueryParams(params) {
    return new URLSearchParams(params).toString();
  }

  /**
   * Decodes a URL-encoded query string into an object of query parameters.
   * @param {string} queryString - The encoded query string.
   * @returns {Object} - The decoded query parameters as key-value pairs.
   */
  static decodeQueryParams(queryString) {
    return Object.fromEntries(new URLSearchParams(queryString).entries());
  }

  /**
   * Adds or updates query parameters in a given pathname.
   * @param {string} pathname - The pathname to update.
   * @param {Object} params - The query parameters to add or update.
   * @returns {string} - The updated URL with the new query parameters.
   */
  static addOrUpdateQueryParams(pathname, params) {
    const url = new URL(window.location.origin + pathname);
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    url.search = searchParams.toString();
    return url.pathname + url.search;
  }

  static createGlobalQueryParams(params) {
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    return searchParams.toString();
  }

  static createLocalQueryParams(params) {
    let searchParams = "";
    Object.entries(params).forEach(([key, value]) => {
      searchParams += `&${key}=${value}`;
    });
    return searchParams;
  }

  /**
   * Removes a specific query parameter from the pathname.
   * @param {string} pathname - The pathname to update.
   * @param {string} param - The query parameter to remove.
   * @returns {string} - The updated URL with the specified query parameter removed.
   */
  static removeQueryParam(pathname, param) {
    const url = new URL(window.location.origin + pathname);
    const searchParams = new URLSearchParams(url.search);

    searchParams.delete(param);

    url.search = searchParams.toString();
    return url.pathname + url.search;
  }

  /**
   * Removes all query parameters from the pathname.
   * @param {string} pathname - The pathname to update.
   * @returns {string} - The updated URL without any query parameters.
   */
  static removeAllQueryParams(pathname) {
    const url = new URL(window.location.origin + pathname);
    url.search = "";
    return url.pathname + url.search;
  }
}

export default URLService;
