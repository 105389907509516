/* eslint-disable no-console */
import axios from "axios";
import { SAMDOCK_BASE_API_URL } from "../Constants";

const checkCompanyOrPersonExists = async ({ id, endpoint, token }) => {
  try {
    const response = await axios.get(`${SAMDOCK_BASE_API_URL}/${endpoint}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    // console.log(error.message, error);
    return {
      status: 404,
    };
  }
};

export default checkCompanyOrPersonExists;
