export const CameraError = {
  camera: "Please enter the camera name",
  cameraWithOneAlphabet: "Please enter atleast one alphabetic character",
  cameraWithNoSpecialCharacters: "Name cannot contain special characters",
  invalidCamera: "Name cannot begin with a special characters",
  manufacturer: "Please select a manufacturer ",
  ip: "Please enter the IP/DNS address",
  port: "Please enter the port",
  invalidPort: "Please provide valid port",
  username: "Please enter the username",
  password: "Please enter the password",
  channelId: "Please enter the channel ID",
  nvrChannelId: "Channels are required",
  region: "Please select a city",
  branch: "Please select a location",
  rtsp: "Please enter the RTSP URL",
  hls: "Please enter the HLS URL",
  invalidIp: "Please check the IP/DNS address",
  invalidURL: "Please check the URL",
  invalidRTSP: "RTSP URL is invalid",
  invalidHLS: "HLS URL is invalid",
  cameraOfflineAlertDuration: "You cannot enter a duration less than 5 minutes",
  notANumber: "Offline duration must be a number",
  email: "Please enter a email",
  emailValidation: "Please provide valid email ids",
  openEyeDevice: "Please select OpenEye device",
  onPremiseDevice: "Please select on-premise device",
  localDeviceNotConnected: "Please complete local device connection flow",
  cameraNotAvailable: "Select another OpenEye device",
  selectedCameras: "Select at least one camera",
  localIp:
    "This seems to be a local IP/DNS address. Please enter a public address to connect your camera.",
  proxyRTSPMain: "Please enter Proxy URL",
};

export const CAMERA_FIELD_VALIDATION_MSG = {
  camera: "Name cannot begin with a number or contain special characters.",
};

export const dvrError = {
  dvr: "Please enter the recorder name",
  manufacturer: "Please select a manufacturer",
  ip: "Please enter the IP/DNS address",
  port: "Please enter the port",
  username: "Please enter the username",
  password: "Please enter the password",
  channels: "Please select the channel count",
  region: "Please select a city",
  branch: "Please select a location",
  invalidIp: "Please check the IP/DNS address",
  emailValidation: "Please provide valid email ids",
  cameraOfflineAlertDuration: "You cannot enter a duration less than 5 minutes",
  notANumber: "Offline duration must be a number",
  email: "Please enter a email",
  localIp:
    "This seems to be a local IP/DNS address. Please enter a public address to connect your camera.",
};

export const cameraFetchLabel = {
  4: "It generally takes less than 30 seconds to fetch cameras from a 4 channels  ",
  8: "It generally takes less than 1 minute to fetch cameras from a 8 channels  recorder",
  16: "It generally takes less than 2 minutes  to fetch cameras from a 16 channels  recorder",
  32: "It generally takes less than 3 minutes  to fetch cameras from a 32 channels  recorder",
  64: "It generally takes less than 5 minutes  to fetch cameras from a 64 channels  recorder",
  defaultLabel: "",
};
export const getFetchCameraLoadingLabel = (channels) => {
  try {
    return cameraFetchLabel[parseInt(channels)];
  } catch (e) {
    return cameraFetchLabel.defaultLabel;
  }
};
