import { useEffect } from "react";
import useRequest from "./useRequest";

// TODO: can merge the existing useMakeRequest hook temp using this
function useMakeRequest({ fetcher, initialData, config }) {
  const [{ loading, data, updateData, hasError }, request] = useRequest({
    request: fetcher,
    initialData,
    config,
  });

  useEffect(() => {
    request();
  }, [request]);

  return {
    data,
    loading,
    hasError,
    updateData,
    fetchData: request,
  };
}

export default useMakeRequest;
