import React from "react";

function IconRefresh() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.45457 12C5.45457 8.38364 8.38366 5.45454 12 5.45454C13.1073 5.45454 14.1437 5.73272 15.06 6.21274L16.6528 4.62C15.3 3.77451 13.7127 3.27271 12 3.27271C7.17817 3.27271 3.27273 7.17815 3.27273 12H0L4.36362 16.3636L8.72725 12H5.45457Z"
        fill="#545454"
      />
      <path
        d="M19.6364 7.63623L15.2728 11.9999H18.5455C18.5455 15.6162 15.6164 18.5453 12 18.5453C10.8928 18.5453 9.8564 18.2671 8.94004 17.7871L7.34729 19.3799C8.7 20.2253 10.2873 20.7271 12 20.7271C16.8218 20.7271 20.7273 16.8217 20.7273 11.9999H24.0001L19.6364 7.63623Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconRefresh;

export function IconRefreshNew() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.64236 1.95573C3.81102 0.945238 5.33445 0.333984 7.00065 0.333984C10.6825 0.333984 13.6673 3.31875 13.6673 7.00065C13.6673 8.42472 13.2208 9.74452 12.4601 10.8277L10.334 7.00065H12.334C12.334 4.05513 9.94618 1.66732 7.00065 1.66732C5.5672 1.66732 4.26583 2.23283 3.30747 3.15293L2.64236 1.95573ZM11.3589 12.0456C10.1903 13.0561 8.66685 13.6673 7.00065 13.6673C3.31875 13.6673 0.333984 10.6825 0.333984 7.00065C0.333984 5.57656 0.780511 4.25676 1.54118 3.17361L3.66732 7.00065H1.66732C1.66732 9.94618 4.05513 12.334 7.00065 12.334C8.43412 12.334 9.73545 11.7685 10.6939 10.8484L11.3589 12.0456Z"
        fill="#3766E8"
      />
    </svg>
  );
}
