export const SET_FORM_STEP = "SET_FORM_STEP";
export const UPDATE_ERROR_FIELDS = "UPDATE_ERROR_FIELDS";
export const UPDATE_DEVICE_TYPES_SUB_STEP = "UPDATE_DEVICE_TYPES_SUB_STEP";
export const INITIALIZE_FORM_OPTIONS = "INITIALIZE_FORM_OPTIONS";
export const UPDATE_FORM_OPTIONS = "UPDATE_FORM_OPTIONS";
export const SET_DEVICE_TYPE = "SET_DEVICE_TYPE";
export const CLEAR_DEVICE_FORM_DATA = "CLEAR_DEVICE_FORM_DATA";
export const SET_ADDED_DEVICE_DATA = "SET_ADDED_DEVICE_DATA";
export const UPDATE_TECHNICAL_DETAILS = "UPDATE_TECHNICAL_DETAILS";
export const SET_WO_CONNECT_DETAILS = "SET_WO_CONNECT_DETAILS";
export const SET_WO_CONNECT_VERIFY_DETAILS = "SET_WO_CONNECT_VERIFY_DETAILS";
export const SET_CONNECTED_CAMERAS = "SET_CONNECTED_CAMERAS";
export const SET_CONNECTED_CAMERAS_ERROR = "SET_CONNECTED_CAMERAS_ERROR";
export const UPDATED_SELECTED_CAMERAS = "UPDATED_SELECTED_CAMERAS";
export const UPDATE_OTHER_DETAILS = "UPDATE_OTHER_DETAILS";
export const SUBMIT_NEW_DEVICE_FORM = "SUBMIT_NEW_DEVICE_FORM";
export const RESET_SUBMIT_FORM = "RESET_SUBMIT_FORM";
export const INITIATE_LOCAL_CAMERA_FORM = "INITIATE_LOCAL_CAMERA_FORM";
export const ADD_NEW_LOCATION = "ADD_NEW_LOCATION";
export const REDIRECT_ON_SAVE = "REDIRECT_ON_SAVE";
export const SETUP_WO_CONNECT = "SETUP_WO_CONNECT";
export const SET_EDITING_MODE = "SET_EDITING_MODE";
export const ADD_DEVICE_DETAILS = "ADD_DEVICE_DETAILS";
export const EDIT_OTHER_DETAIL = "EDIT_OTHER_DETAIL";
export const SHOW_RTSP_AUTH_MODAL = "SHOW_RTSP_AUTH_MODAL";
export const DISABLED_FIELDS = "DISABLED_FIELDS";
export const IS_REFRESH = "IS_REFRESH";
export const SET_ERROR = "SET_ERROR";
export const DEVICE_OFFLINE_EMAIL_LIST = "DEVICE_OFFLINE_EMAIL_LIST";
export const SET_SUB_STEPS = "SET_SUB_STEPS";
export const RESET_SUB_STEPS = "RESET_SUB_STEPS";
export const SET_EDIT_TYPE = "SET_EDIT_TYPE";
export const SET_IS_GLOBAL_SUBMIT = "SET_IS_GLOBAL_SUBMIT";
export const SHOW_BACK_BUTTON = "SHOW_BACK_BUTTON";
export const SET_PRE_CONNECTED_CAMERAS = "SET_PRE_CONNECTED_CAMERAS";
export const ADD_DEVICE_STEPS = {
  SELECT_DEVICE_TYPE: 1,
  ADD_TECHNICAL_DETAILS: 2,
  OTHER_DETAILS: 3,
};

export const DEVICE_TYPES = {
  OPEN_EYE_CLOUD_CAMERA: "openEyeCloudCamera",
  CLOUD_CAMERA: "cloudCamera",
  CLOUD_NVR: "cloudNVR",
  LOCAL_CAMERA: "localCamera",
  EDGE_SETUP: "edgeSetup",
  STAND_ALONE_CAMERA: "standAloneCamera",
};

export const DEVICE_TYPES_SUB_STEP = {
  OPEN_EYE_DEVICE: "openEyeDevice",
  ADD_CAMERAS: "addCameras",
  ADD_STANDALONE_RECORDER: "addStandaloneRecorder",
  SETUP_WO_CONNECT: "setupWoConnect",
  FETCH_WO_CONNECT_SETUP: "fetchWoconnectSetup",
  STANDALONE_ADDITIONAL_DETAILS: "standAloneAdditionalDetails",
};

export const FETCHING_PROTECTED_FIELD_TYPES = {
  MAIN: "main",
  PROXY: "proxySub",
};
export const CAMERA_TYPES = {
  RTSP: "rtsp",
  HLS: "hls",
};
export const SCAN_FOR = {
  CAMERA: "camera",
  RECORDER: "dvr",
};

export const CHANNELS_LIST = [
  { label: "4", value: "4" },
  { label: "8", value: "8" },
  { label: "16", value: "16" },
  { label: "32", value: "32" },
  { label: "64", value: "64" },
];
export const smartStreamerFramerate = [
  { value: "1", label: "1" },
  { value: "3", label: "3" },
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
];

export const smartStreamerBitRate = [
  { value: "256", label: "256" },
  { value: "512", label: "512" },
  { value: "758", label: "758" },
  { value: "1024", label: "1024" },
  { value: "2048", label: "2048" },
];

export const smartStreamerOutPutQuality = [
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
];

export const smartStreamerDetectionMode = [
  {
    label: "Default",
    value: "default",
  },
  {
    label: "Person",
    value: "person",
  },

  {
    label: "Vehicle",
    value: "vehicle",
  },
];
export const smartStreamerResolution = [
  {
    value: "640x480",
    label: "640 x 480",
    width: "640",
    height: "480",
  },

  {
    value: "1280x720",
    label: "1280 x 720",
    width: "1280",
    height: "720",
  },
  {
    value: "1920x1080",
    label: "1920 x 1080",
    width: "1920",
    height: "1080",
  },
];
export const STEPS_OPTIONS = [
  {
    title: "Camera Type",
    description: "All fields are mandatory",
    type: ADD_DEVICE_STEPS.SELECT_DEVICE_TYPE,
  },
  {
    title: "Connection Details",
    description: "All fields are mandatory",
    type: ADD_DEVICE_STEPS.ADD_TECHNICAL_DETAILS,
  },
  {
    title: "Additional Settings",
    description: "Some fields are optional",
    type: ADD_DEVICE_STEPS.OTHER_DETAILS,
  },
];

export const getValue = (selection) => (selection ? selection?.value : undefined);

export const shouldIncludeCredentials = ({ enterCredentials, deviceType }) =>
  enterCredentials || deviceType === DEVICE_TYPES.CLOUD_NVR;
