import { lazy } from "react";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import { PAGE_TITLES } from "../../../Services/checkWobotDomain";

const AccountProfile = lazy(() => import("../../Components/AccountProfileComponent"));

function AccountProfileContainer() {
  return (
    <PageContainer initialPageTitle={PAGE_TITLES.accountProfile} withLayout={false}>
      <AccountProfile />
    </PageContainer>
  );
}
export default AccountProfileContainer;
