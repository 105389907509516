import React from "react";
import { Button } from "wobotui";
import Styles from "./StyleInsightDowload.module.scss";
import InsightsDownloadIllustration from "../../IllustrationComponent/InsightsDownloadIllustration";
import IconWarningToast from "../IconsComponent/IconWarningToast";
import useDownloadInsights from "./hooks/useDownloadInsights";

function InsightsDownload() {
  const downloadInsights = useDownloadInsights();

  return (
    <div className={Styles.wrapper}>
      <img src="/assets/images/wobot-logo.png" alt="logo" />
      <div>
        <InsightsDownloadIllustration />
      </div>
      <div className={Styles.report_status_info}>
        <h6>Report is ready!</h6>
        <p>Your report should be downloaded shortly</p>
        <div className={Styles.report_download_wrapper}>
          <p>Didn’t download?</p>
          <Button
            onClick={() => downloadInsights()}
            type="button"
            variant="link"
          >
            Click here
          </Button>
        </div>
      </div>
      <div className={Styles.event_summary_block}>
        <IconWarningToast color="#3766E8" style={{ position: "absolute", left: "14px", top: "12px" }} />
        <div className={Styles.event_info}>
          {/* <p className={Styles.title}>145 events detected in this report.</p> */}
          <p>See how their trend looks like</p>
        </div>
        <a href="https://app.wobot.ai/" target="_blank" rel="noreferrer">Go to Dashboard</a>
      </div>
    </div>
  );
}

export default InsightsDownload;
