import React, { lazy, Suspense } from "react";
import LoginSkeleton from "../Components/SkeletonComponent/CommonSkeleton/LoginSkeleton";

const TwoFactorAuthCodeVerificationComponent = lazy(
  () =>
    import(
      "../Components/LoginComponent/TwoFactorAuthCodeVerification/TwoFactorAuthCodeVerificationComponent"
    )
);

function TwoFactorAuthCodeVerification() {
  return (
    <Suspense
      fallback={<LoginSkeleton width="60vw" height={460} titleHeight={100} titleWidth="60vw" />}
    >
      <TwoFactorAuthCodeVerificationComponent />
    </Suspense>
  );
}

export default TwoFactorAuthCodeVerification;
