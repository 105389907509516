const forgotPassWord = {
  updatedPassword: {
    updatePasswordSuccess: "password updated successfully | Forgot password",
  },
};

const setUpPassword = {
  setUpPasswordComplete: {
    PasswordSetUpSuccess: "password updated successfully | Forgot password",
  },
};

const Settings = {
  companyProfile: {
    updateDetail: "Company detail Updated successfully | Settings",
    updateChangesBotton: "Clicked on update changes of company detail page | Settings",
  },
  EmployeeList: {
    invite: "resent invite employee | Settings",
    delete: "deleted employee | Settings",
    active: "Actived employee | Settings",
    inactive: "Inactived employee | Settings",
    editClick: "Clicked on edit employee | Settings",
    addClick: "Clicked on add employee | Settings",
    createdEmployee: "Employee created | Settings",
    editedEmployee: "Employee edited | Settings",
    cancelAddClick: "Canceled add employee | Settings",
    cancelEditClick: "Canceled edit employee | Settings",
  },
  EmployeeDetail: {
    updatedPassword: "password Updated | Settings",
    updatePasswordButton: "Clicked on update password | Settings",
    cancelUpdatePassword: "Canceled update password pop up | Settings",
    deletedEmployee: "deleted employee | Settings",
  },
  region: {
    cancelEditModal: "Canceled create region pop up | Settings",
    cancelAddModal: "Canceled edit region pop up | Settings",
    delete: "Region deleted | Settings",
    create: "Region created | Settings",
    edit: "Region edited | Settings",
    editClick: "Clicked on edit region | Settings",
    deleteClick: "Clicked on delete region | Settings",
    addClick: "Clicked on add region | Settings",
  },
  subRegion: {
    cancelAddModal: "Canceled create city pop up | Settings",
    cancelEditModal: "Canceled edit city pop up | Settings",
    delete: "City deleted | Settings",
    create: "City created | Settings",
    edit: "City edited | Settings",
    editClick: "Clicked on edit city | Settings",
    deleteClick: "Clicked on delete city | Settings",
    addClick: "Clicked on add city | Settings",
  },
  location: {
    cancelAddModal: "Canceled create branch pop up | Settings",
    cancelEditModal: "Canceled edit branch pop up | Settings",
    delete: "Branch deleted | Settings",
    create: "Branch created | Settings",
    edit: "Branch edited | Settings",
    editClick: "Clicked on edit branch | Settings",
    addClick: "Clicked on add branch | Settings",
    deleteClick: "Clicked on delete branch | Settings",
  },
  schedule: {
    delete: "Schedule deleted | Settings",
    deleteClick: "Clicked on delete schedule | Settings",
    addClick: "Clicked on add schedule | Settings",
  },
  addScheduleModal: {
    settingscancelAddModal: "Canceled created schedule pop up | Settings",
    checklistcancelAddModal: "Closed add schedule pop up | Task",
    checklistCreateSchedule: "Added new schedule | Task",
    settingsCreateSchedule: "Schedule created | Settings",
  },
  employeeProfile: {
    updateChangesClick: "Clicked On Updated changes of profile details page | Settings",
    updatedProfileSuccess: "Updated User profile details successfully | Settings",
  },
  tat: {
    cancelAddModal: "Canceled create TAT pop up | Settings",
    cancelEditModal: "Canceled edit TAT pop up | Settings",
    detele: "TAT deleted | Settings",
    create: "TAT created | Settings",
    edit: "TAT edited | Settings",
    active: "TAT activted | Settings",
    inactive: "TAT inactivted | Settings",
    editClick: "Clicked on edit TAT | Settings",
    deleteClick: "Clicked on delete TAT | Settings",
    addClick: "Clicked on add TAT | Settings",
  },
};
const AccountSettings = {
  employeeProfile: {
    updateChangesClick: "Clicked On Updated changes of profile details page | Settings",
    updatedProfileSuccess: "Updated User profile details successfully | Settings",
  },
};
const GroupTicketing = {
  TicketDetailTitles: {
    fail: "Ticket marked as incorrect successfully | Ticketing",
    pass: "Ticket marked as correct successfully | Ticketing",
    open: "Ticket opened successfully | Ticketing",
    close: "Ticket closed successfully | Ticketing",
    addedComment: "Added comment on audit logs | Ticketing",
    selectAllClick: "Clicked on select all | Ticketing",
    deselectAllClick: "Clicked on deselect all | Ticketing",
    bulkFail: "Bulk tickets marked as incorrect successfully | Ticketing",
    bulkPass: "Bulk tickets as correct successfully | Ticketing",
    bulkOpen: "Bulk tickets opened successfully | Ticketing",
    bulkClose: "Bulk ticketss closed successfully | Ticketing",
    bulkDelete: "Bulk tickets deleted successfully | Ticketing",
  },
};

const MyTask = {
  List: {
    cardLinkClick: "Clicked on a scheduled task | My Task",
  },
  Detail: {
    resetAllClick: "Clicked on Reset All | My Task",
    viewButtonClick: "Clicked on View button | open tagging pop up | My Task",
    deleteClick: "Clicked on Delete icon | My Task",
    closeModal: "Closed tagging pop up | My Task",
    submitClick: "Clicked on submit Task | My Task",
    eventCreate: "Event created successfully | My Task",
    captureClick: "Clicked on capture button | My Task",
    prevClick: "Clicked on previous button | My Task",
    nextClick: "Clicked on next button | My Task",
  },
};

const Task = {
  HomeListPage: {
    noChecklist: "No checklist | Task",
    addTaskClick: "Clicked on add task | Task",
    setUpTaskClick: "Clicked on set up task | Task",
    browseCheckListAndTaskClick: "Clicked on Browse Checklist and Tasks | Task",
    addCameraFirstClick: "Click on Add Camera First | Task",
    onSelectChecklist: "Selected a checklist | Task",
    automateNowClick: "Clicked on Automate Now | Task",
  },
  configPage: {
    saveClick: "Clicked on monitor detail save | Config Page | Task",
    selectLocation: "Clicked on select location | Config Page | Task",
    saveTaskSucc: "Task saved successfully | Config Page | Task",
    editClick: "Clicked on edit task setup | Config Page | Task",
    stopClick: "Clicked on stop task setup | Config Page | Task",
    startClick: "Clicked on start task setup | Config Page | Task",
    deleteClick: "Clicked on delete task setup | Config Page | Task",
  },
};

const Onboarding = {
  AddCameraModal: {
    addedCamera: "Added camera successfully | Onboarding",
    editedCamera: "Edited camera details | Onboarding",
    canceledEditModal: "Canceled Edit Camera pop up | Onboarding",
    canceledAddModal: "Canceled Add Camera pop up | Onboarding",
    updateCameraClick: "Clicked on Update Camera | Onboarding",
    addCameraClick: "Clicked on Add Camera | Onboarding",
  },
  AddDvrModal: {
    cancelEditNvrPopUp: "Canceled Edit DVR/NVR pop up | Onboarding",
    cancelAddNvrPopUp: "Canceled Add DVR/NVR pop up | Onboarding",
    added: "Added DVR successfully | DVR/NVR | Onboarding",
    addClick: "Clicked on Add Dvr | Onboarding",
  },
  connectCamera: {
    nvrCardClick: "Clicked on Add DVR/NVR Detail card | dvr pop up open | Onboarding",
    cameraCardClick: "Clicked on Add direct camera card | camera pop up open | Onboarding",
    skipNowClick: "Click on Skip for now | connect camera page | Onboarding",
    nextClick: "Click on next | Add your camera page | Onboarding",
  },
  AddTeam: {
    added: "Added teammates successfully | Add team page | Onboarding",
    skipNowClick: "Click on Skip for now | Add team page | Onboarding",
    inviteAndContinueClick: "Click on Invite & Continue | Add team page | Onboarding",
  },
  AddLocation: {
    mediaAdded: "media added successfully | Onboarding",
    inputAdded: "input added successfully | Onboarding",
    continueClick: "Clicked on continue of add location | Onboarding",
    downloadSampleClick: "Clicked on download sample | Onboarding",
  },
  AddCompany: {
    added: "User sign up successfully | Onboarding",
  },
};

const WoCam = {
  AddCameraModal: {
    addedCamera: "Added camera successfully | Camera | WoCam",
    editedCamera: "Edited camera details | Camera | WoCam",
    canceledEditModal: "Canceled Edit Camera pop up | Camera | WoCam",
    canceledAddModal: "Canceled Add Camera pop up | Camera | WoCam",
    updateCameraClick: "Clicked on Update Camera | Camera | WoCam",
    addCameraClick: "Clicked on Add Camera | Camera | WoCam",
  },
  CameraList: {
    deleteSuccessCamera: "Deleted camera successfully | Camera | WoCam",
    activeSuccessCamera: "Activated camera successfully | Camera | WoCam",
    inactiveSuccessCamera: "Inactived camera successfully | Camera | WoCam",
    viewCameraClick: "Clicked on view camera details | Camera | WoCam",
    addCameraClick: "Open add Camera pop up",
    viewCameraDetailClick: "Clicked on view camera details | Camera | WoCam",
  },
  CameraDetail: {
    resetFrameClick: "Clicked on Reset Frame | Camera | WoCam",
    frameFetchSuccess: "Reset Frame successfully | Camera | Wocam",
    deleteSuccessCamera: "Deleted camera successfully | Camera | WoCam",
    activeSuccessCamera: "Activated camera successfully | Camera | WoCam",
    inactiveSuccessCamera: "Inactived camera successfully | Camera | WoCam",
    viewRecordedVideosClick: "Clicked on playback | Camera | WoCam",
    setUpDirectRecordingSuccess: "Recording set up successfully | Camera | WoCam",
  },
  configPage: {
    saveClick: "Clicked on monitor detail save | Config Page | Task",
    selectLocation: "Clicked on select location | Config Page | Task",
    saveTaskSucc: "Task saved successfully | Config Page | Task",
    editClick: "Clicked on edit task setup | Config Page | Task",
    stopClick: "Clicked on stop task setup | Config Page | Task",
    startClick: "Clicked on start task setup | Config Page | Task",
    deleteClick: "Clicked on delete task setup | Config Page | Task",
  },
  LiveView: {
    noLiveView: "No live View added | Live | WoCam",
    deleteClick: "Clicked on delete view | Live | WoCam",
    deleted: "Deleted view successfully | Live | WoCam",
    stopClick: "Clicked on stop live view | Live | WoCam",
    startClick: "Clicked on start live view | Live | WoCam",
  },
  AddViewModal: {
    updatedView: "Updated view | Live | WoCam",
    createdView: "Created view | Live | WoCam",
    cancelEditView: "Closed edit view pop up | Live | WoCam",
    cancelAddView: "Closed create view pop up | Live | WoCam",
    editViewClick: "Click on Edit view | Open Edit view pop up | Live | WoCam",
  },
  RecordingList: {
    deleted: "Deleted DVR/ successfully| DVR/NVR | WoCam",
    actived: "Actived DVR/ successfully| DVR/NVR | WoCam",
    inactived: "Inactived DVR/ successfully| DVR/NVR | WoCam",
    viewDetailsClick: "Clicked on view DVR details | DVR detail page opened | DVR/NVR | WoCam",
    addDvrClick: "Clicked on Add DVR/NVR | Open add DVR pop up | DVR/NVR | WoCam",
  },
  RecordingDetail: {
    deleted: "Deleted DVR/ successfully| DVR/NVR | WoCam",
    actived: "Actived DVR/ successfully| DVR/NVR | WoCam",
    inactived: "Inactived DVR/ successfully| DVR/NVR | WoCam",
  },
  AddDvrModal: {
    cancelEditNvrPopUp: "Canceled Edit DVR/NVR pop up | DVR/NVR | WoCam",
    cancelAddNvrPopUp: "Canceled Add DVR/NVR pop up | DVR/NVR | WoCam",
    added: "Added DVR successfully | DVR/NVR | WoCam",
    edited: "DVR edited successfully | DVR/NVR | WoCam",
    editClick: "Clicked on Update DVR/NVR | DVR/NVR | WoCam",
    addClick: "Clicked on Add Dvr | DVR/NVR | WoCam",
  },
  RefetchCameraModal: {
    fetchCamClick: "Click on fetch Cameras | DVR/NVR | WoCam",
    fetchCamSuccess: "fetched cameras successfully| DVR/NVR | WoCam",
    updateClick: "Clicked on Update from refetch cameras pop up| DVR/NVR | WoCam",
    updateCameraSuccess: "Updated cameras successfully| DVR/NVR | WoCam",
    closeRefetchPopUp: "Closed refetch cameras pop up | DVR/NVR | WoCam",
  },
  PlayBack: {
    previewAndTagClick: "Clicked on Preview & Tags | open playback pop up | Playback | WOCam",
    resetLatestClick: "Clicked on Reset Latest | Playback | WoCam",
    deleteClick: "Clicked on Delete icon | Playback | WoCam",
    cancelPopUp: "Closed playback pop up | Playback | WoCam",
    submitClick: "Clicked on submit | Playback | WoCam",
    eventCreated: "Event created successfully | Playback | WoCam",
    resetAllClick: "Clicked on Reset All | Playback | WoCam",
    captureClick: "Clicked on capture button | Playback | WoCam",
  },
};

const Event = {
  EventList: {
    deleted: "Deleted compliance event | Event",
    eventDetailOpen: "Compliance event details opened | Event",
  },
  EventDetail: {
    updatedStatus: "updated ticket status | Event",
    deleted: "deleted ticket | Event",
  },
};

export {
  Event,
  GroupTicketing,
  MyTask,
  Onboarding,
  Settings,
  AccountSettings,
  Task,
  WoCam,
  forgotPassWord,
  setUpPassword,
};
