/**
 * Access list for role based access
 * */
export const CHECKLISTVIEW = "checklistView";
export const CHECKLISTCHANGESTATUS = "checklistChangeStatus";
export const CHECKLISTSETUP = "checklistSetup";
export const CHECKLISTEDIT = "checklistEdit";

// Wocam
export const WOCAMVIEWCAMERA = "woCamViewCamera";
export const WOCAMMANAGECAMERA = "woCamManageCamera";
export const WOCAMVIEWRECORDER = "woCamViewRecorder";
export const WOCAMMANAGERECORDER = "woCamManageRecorder";
export const WOCAMACCESSLIVEVIEW = "woCamAccessLiveView";
export const WOCAMMANAGELIVEVIEW = "woCamManageLiveView";
export const WOCAMSETUPDIRECTRECORDING = "woCamSetupDirectRecording";
export const WOCAMVIEWDIRECTRECORDINGS = "woCamViewDirectRecordings";
export const WOCAMVIEWANDTAGRECORDINGS = "woCamViewAndTagRecordings";
export const WOCAMADVANCECAMERASETTINGS = "woCamAdvanceCameraSettings";
export const WOCAMMANAGEPROXY = "woCamManageProxy";
export const WOCAMCOPYRTSP = "woCamCopyRTSP";

// Playback
export const PLAYBACKVIDEODOWNLOAD = "playbackVideoDownload";

// Task
export const TASKSVIEWTASKS = "tasksViewTasks";
export const TASKSMANAGETASKS = "tasksManageTasks";
export const TASKSDELETETICKET = "tasksDeleteTicket";
export const TASKSMANAGEENVIRONMENT = "tasksManageEnvironment";
export const TASKSAVEOVERALLSETTINGS = "tasksSaveOverallSettings";
export const ACCESSTASKBROWSE = "accessTaskBrowse";

// settings
export const SETTINGSVIEWLOCATIONS = "settingsViewLocations";
export const SETTINGSMANAGELOCATIONS = "settingsManageLocations";
export const SETTINGSVIEWSCHEDULE = "settingsViewSchedule";
export const SETTINGSMANAGESCHEDULE = "settingsManageSchedule";
export const SETTINGSVIEWEMPLOYEE = "settingsViewEmployees";
export const SETTINGSMANAGEEMPLOYEE = "settingsManageEmployee";
export const SETTINGSMANAGECOMPANYPROFILE = "settingsManageCompanyProfile";
export const SETTINGSMANAGEPLAN = "settingsManagePlan";
export const SETTINGSMANAGEROLESANDGROUPS = "settingsManageRolesAndGroups";
export const SETTINGSVIEWSHIFT = "settingsViewShift";
export const SETTINGSMANAGESHIFT = "settingsManageShift";
export const SETTINGSMANAGEINTEGRATIONS = "manageIntegrations";

// Ticketing
export const TICKETINGANDCOMPLIANCEVIEWDETAILS = "ticketingAndComplianceViewDetails";
export const TICKETINGANDCOMPLIANCECHANGESTATUS = "ticketingAndComplianceChangeStatus";
export const TICKETINGANDCOMPLIANCEDELETETICKET = "ticketingAndComplianceDeleteTicket";

// Analytics
export const ACCESSANALYTICS = "accessAnalytics";
export const ACCESSPEOPLEANALYTICS = "accessPeopleAnalytics";
export const ACCESSCUSTOMERANALYTICS = "accessDriveThru";

// Events
export const EVENTSDELETE = "deleteEvents";
export const EVENTSADVANCESETTINGS = "eventsAdvanceSettings";
export const COPYCONFIG = "copyConfig";

// Drivethru and Personsuit
export const MANAGEDRIVETHRU = "manageDriveThru";
export const VERIFYDRIVETHRUJOURNEY = "verifyDrivethruJourney";
export const VERIFYCUSTOMERJOURNEY = "verifyCustomerJourney";
export const DELETEDRIVETHRUJOURNEY = "deleteDriveThruJourney";
export const MANAGEPERSONSUITE = "managePersonSuite";
export const ACCESSDRIVETHRU = "accessDriveThru";
export const ACCESSPERSONSUITE = "accessPersonSuite";
export const MANAGEDRIVETHRUREPORT = "manageDriveThruReport";

// Review Options
export const SHOWREVIEWOPTION = "showReviewOption";
export const ACCESSPOSANALYTICS = "accessPosAnalytics";

// Leaderboard
export const ACCESSLEADERBOARD = "accessLeaderBoard";

// Threshold settings
export const ADVANCETHRESHOLDSETTINGS = "advanceThresholdSettings";
