import { API_BASE_URL, API_CONFIG_NO_MANIFEST_CALL, API_CONFIG_SHOWLOADER } from "../../Constants";
import { WOCAMADVANCECAMERASETTINGS } from "../../Constants/AccessList";
import callApi, { handleLoader } from "../../Services/callApi";
import { getHeaders } from "../../Services/getHeaders";
import Roles from "../../Services/Roles";
import { showAlert } from "../../Services/showAlert";
import updateManifest from "../../Services/updateManifest";
import { CAMERA_TYPES, DEVICE_TYPES, getValue, SCAN_FOR, shouldIncludeCredentials } from "../State";
import Service from "../utils/Service";

const checkConnection = async ({
  host,
  port,
  username,
  password,
  channelId,
  manufacturer,
  cloudAIProcessing,
  cloudAVProcessing,
  edgeProcessing,
  streamType,
  podRestart,
}) => {
  const apiBody = Service.trimPayload({
    manufacturer,
    host,
    port,
    username,
    password,
    channelId,
    cloudAIProcessing,
    cloudAVProcessing,
    edgeProcessing,
    streamType,
    podRestart,
  });

  const url = `${API_BASE_URL}/camera/connection/check`;
  const method = "POST";
  const res = await callApi(
    url,
    {
      method,
      body: JSON.stringify(apiBody),
    },
    {
      callManifest: false,
      showLoader: true,
      loaderLabel: "Wait for sometime to complete the selected action",
    }
  );
  return res;
};

export const getApiBodyPayload = ({ newDeviceData, deviceInfo }) => {
  const {
    deviceType,
    technicalDetails: {
      region,
      location,
      cameraName,
      manufacturerName,
      enterCredentials,
      publicURL,
      ipAddress,
      port,
      nvrUsername,
      nvrPassword,
      channelId,
      nvrChannelId,
      isProxyStream,
      cameraType,
    },
  } = newDeviceData;

  const includeCredentials = shouldIncludeCredentials({
    enterCredentials,
    deviceType,
  });

  return {
    camera: cameraName,
    manufacturer: getValue(manufacturerName),
    rtsp: publicURL,
    region: region?.parent,
    city: getValue(region),
    location: getValue(location),
    timezone: deviceInfo?.timeZone || null,
    channels: deviceType === DEVICE_TYPES.CLOUD_NVR ? getValue(nvrChannelId) : undefined,
    channelId: includeCredentials ? channelId : "",
    host: includeCredentials ? ipAddress : "",
    port: includeCredentials ? port : "",
    username: includeCredentials ? nvrUsername : "",
    password: includeCredentials ? nvrPassword : "",
    rtspMain: includeCredentials ? "" : publicURL,
    ...(deviceType === DEVICE_TYPES.CLOUD_CAMERA && { isProxyStream }),
    sourceType: cameraType === CAMERA_TYPES.HLS ? "http" : cameraType,
  };
};

export const addCloudCameraRequest = async ({ newDeviceData, deviceInfo }) => {
  const { enterCredentials } = newDeviceData.technicalDetails;

  const apiBody = getApiBodyPayload({ newDeviceData, deviceInfo });

  try {
    if (enterCredentials) {
      const connection = await checkConnection(apiBody);

      apiBody.rtsp = connection.data.rtsp;
      apiBody.rtspMain = enterCredentials ? "" : connection.data.rtspMain;
    }

    const res = await callApi(
      `${API_BASE_URL}/setup/camera`,
      {
        method: "POST",
        body: JSON.stringify(apiBody),
      },
      {
        callManifest: false,
        showLoader: true,
        loaderLabel: "Wait for sometime to complete the selected action",
      }
    );
    if (res.status === 200) {
      const { _id: id } = res.data;
      /**
       * @checkAndCreateLiveView
       * */
      await Service.checkAndCreateLiveView([id]);
    }
    return res;
  } catch (e) {
    showAlert(e, "error");
    throw e;
  }
};

export const addNvrCameras = async ({ newDeviceData, deviceInfo }) => {
  const apiBodyPayload = getApiBodyPayload({ newDeviceData, deviceInfo });

  const apiBody = {
    ...apiBodyPayload,
    dvr: apiBodyPayload.camera,
    camera: undefined, // to remove camera key in case of nvr
    channelId: undefined,
    connectedCamera: newDeviceData.connectedCameras.newSelectedCameras || [],
  };

  try {
    const res = await callApi(
      `${API_BASE_URL}/setup/dvr`,
      {
        method: "POST",
        body: JSON.stringify(apiBody),
      },
      API_CONFIG_NO_MANIFEST_CALL
    );

    if (res.status === 200) {
      /**
       * @checkAndCreateLiveView
       * */
      const camerasPayload = [];

      Object.keys(res.data.cameraDetails || {}).forEach((key) => {
        const { status, _id: id } = res.data.cameraDetails[key];

        if (status === "Active" && camerasPayload.length <= 6) {
          camerasPayload.push(id);
        }
      });

      // if fetched camera selected
      if (camerasPayload.length) {
        // checkAndCreateLiveView will call update manifest along with live vew creation
        await Service.checkAndCreateLiveView(camerasPayload);
      } else {
        // update manifest if no fetched camera selected
        updateManifest();
      }

      // props.data.successCallback();
      // setLoading(false);
      // showAlert(res.message);
      // if (props?.data?.module === "WoCam") {
      //   segmentTrack({ title: WoCam.AddDvrModal.added });
      // } else if (props?.data?.module === "Onboarding") {
      //   segmentTrack({ title: Onboarding.AddDvrModal.added });
      // }
      // closeModal();
    }
    return res;
  } catch (e) {
    // setLoading(false);
    showAlert(e, "error");
    throw e;
  }
};

export const addOpenEyeCloudCameras = async ({ newDeviceData }) => {
  const { openEyeDevice, location } = newDeviceData.technicalDetails;

  const deviceId = getValue(openEyeDevice);
  const locationId = getValue(location);

  if (!locationId || !deviceId) {
    throw new Error({
      status: 403,
      fields: [locationId ? "" : "location", deviceId ? "" : "device"].filter((value) => value),
    });
  }

  const { newSelectedCameras } = newDeviceData.connectedCameras;

  const apiBody = {
    ids: newSelectedCameras,
  };

  const response = await callApi(
    `${API_BASE_URL}/openeye/device/${deviceId}/cameras`,
    {
      method: "POST",
      body: JSON.stringify(apiBody),
    },
    {
      showLoader: true,
      loaderLabel: "Adding cameras",
      callManifest: false,
    }
  );

  return response;
};
export const addStandaloneCameras = async ({ newDeviceData }) => {
  const { onPremiseDevice, location, scanFor } = newDeviceData.technicalDetails;
  const deviceId = getValue(onPremiseDevice);
  const locationId = getValue(location);

  if (!locationId || !deviceId) {
    throw new Error({
      status: 403,
      fields: [locationId ? "" : "location", deviceId ? "" : "device"].filter((value) => value),
    });
  }

  const { newSelectedCameras } = newDeviceData.connectedCameras;

  const apiBody = {
    location: locationId,
    deviceId,
    ...(scanFor === SCAN_FOR.CAMERA && { cameras: newSelectedCameras }),
    ...(scanFor === SCAN_FOR.RECORDER && { ...newSelectedCameras[0] }),
  };

  const response = await callApi(
    `${API_BASE_URL}${
      scanFor === SCAN_FOR.RECORDER ? "/standalone/dvr/save" : "/standalone/camera/save"
    }`,
    {
      method: "POST",
      body: JSON.stringify(apiBody),
    },
    {
      showLoader: true,
      loaderLabel: scanFor === SCAN_FOR.RECORDER ? "Adding recorder" : "Adding camera",
      callManifest: false,
    }
  );

  return response;
};
export async function fetchCameraDetails({ openEyeDeviceId }) {
  const response = await callApi(
    `${API_BASE_URL}/openeye/device/${openEyeDeviceId}/cameras`,
    {
      method: "GET",
    },
    API_CONFIG_NO_MANIFEST_CALL
  );

  if (response.status !== 200) {
    throw response;
  }

  return response;
}
export async function fetchCloudCameraDetails(payload) {
  const response = await callApi(
    `${API_BASE_URL}/fetch-camera`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
    {
      ...API_CONFIG_SHOWLOADER,
      loaderLabel: "Fetching Cameras...",
    }
  );

  if (response.status !== 200) {
    throw response;
  }

  return response;
}

export const getOpenEyeDevices = async () => {
  const response = await callApi(`${API_BASE_URL}/openeye/devices`, {}, API_CONFIG_SHOWLOADER);

  return response;
};
export const getOnPremiseDevices = async ({ locationId }) => {
  const response = await callApi(
    `${API_BASE_URL}/connected/devices/${locationId}`,
    {},
    {
      ...API_CONFIG_SHOWLOADER,
      showLoader: true,
      loaderLabel: "Fetching devices for current location...",
    }
  );

  return response;
};

export const addOpenEyeDeviceToLocation = async ({ deviceId, locationId }) => {
  const response = await callApi(
    `${API_BASE_URL}/openeye/devices`,
    {
      method: "POST",
      body: JSON.stringify({ id: deviceId, locationId }),
    },
    {
      callManifest: false,
      loaderLabel: "Adding recorder to the location",
      showLoader: true,
    }
  );

  return response;
};

export const getOpenEyeDeviceDetails = async ({ deviceId }) => {
  const response = await callApi(`${API_BASE_URL}/dvr/${deviceId}`);

  return response;
};
export const getDeviceDetails = async ({ deviceId, deviceType }) => {
  const endPoint =
    deviceType === "camera" || deviceType === "wmsCamera"
      ? `camera/${deviceId}`
      : `dvr/${deviceId}`;

  handleLoader(true, "Wait for sometime to complete the selected action");

  try {
    const response = await fetch(`${API_BASE_URL}/${endPoint}`, {
      method: "GET",
      headers: getHeaders(),
    });

    if (!response.ok) {
      const errorData = await response.json();
      showAlert(errorData?.message || "Something went wrong", "error");
      throw errorData;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching device details:", error);
    throw error;
  } finally {
    handleLoader(false);
  }
};

export const scanStandaloneDevice = async ({ details }) => {
  const {
    scanFor,
    enterCredentials,
    port,
    onPremiseDevice,
    manufacturerName,
    streamType,
    publicURL,
    ipAddress,
    nvrPassword,
    nvrUsername,
    channelId,
    nvrChannelId,
  } = details || {};
  const scanType = enterCredentials || scanFor === SCAN_FOR.RECORDER ? "manual" : "direct";

  let payload = {
    scanType,
    scanFor,
    deviceId: onPremiseDevice?.value,
    manufacturer: manufacturerName?.value,
    scanningStream: streamType?.value,
  };

  if (scanType === "direct" && scanFor === SCAN_FOR.CAMERA) {
    payload = {
      ...payload,
      stream: publicURL,
    };
  } else {
    payload = {
      ...payload,
      host: ipAddress,
      username: nvrUsername,
      password: nvrPassword,
      port: Number(port),
      ...(scanFor === SCAN_FOR.CAMERA
        ? { channelId: Number(channelId) }
        : { maxChannel: Number(nvrChannelId?.value) }),
    };
  }
  const response = await callApi(
    `${API_BASE_URL}/standalone/scan`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
    {
      callManifest: false,
      loaderLabel: `Fetching ${payload?.scanFor === SCAN_FOR.CAMERA ? "Camera" : "Recorders"}...`,
      showLoader: true,
    }
  );

  return response;
};

export async function submitOtherDetailsToCamera({ cameraId, otherDetails }) {
  const { cameraOfflineAlertDuration, emailIds, smartStreamer, backgroundRedaction } = otherDetails;
  const payload = {
    cameraOfflineAlertDuration,
    emailIds,
    smartStreamer,
    backgroundRedaction,
  };
  const response = callApi(
    `${API_BASE_URL}/camera/stream/update/${cameraId}`,
    {
      method: "PUT",
      body: JSON.stringify(payload),
    },
    {
      callManifest: true,
      showLoader: true,
      loaderLabel: "Wait for sometime to complete the selected action",
    }
  );

  return response;
}
export async function submitOtherDetailsToDevice({ deviceId, otherDetails }) {
  const response = callApi(
    `${API_BASE_URL}/dvr/camera/stream/update/${deviceId}`,
    {
      method: "POST",
      body: JSON.stringify(otherDetails),
    },
    {
      callManifest: true,
      showLoader: true,
      loaderLabel: "Wait for sometime to complete the selected action",
    }
  );

  return response;
}
export async function updateCamera({ newDeviceData, deviceInfo, otherDetails }) {
  const {
    editType,
    technicalDetails: {
      cameraName: camera,
      manufacturerName: manufacturer,
      isProxyStream,
      publicURL,
      proxyRTSPMain,
      cameraType,
    },
    deviceDetails: {
      id: cameraId,
      _location: location,
      _city: city,
      _region: region,
      cameraStream,
      connectedToDeviceId,
    } = {},
    inEditingMode,
  } = newDeviceData || {};

  const { cloudAIProcessing, cloudAVProcessing, edgeProcessing, smartStreamerProcessing } =
    cameraStream || {};

  const { cameraOfflineAlertDuration, emailIds, smartStreamer, backgroundRedaction } = otherDetails;
  let rtspFields = {};
  if (publicURL || proxyRTSPMain) {
    if (publicURL) {
      if (editType === "wmsCamera") {
        rtspFields = { stream: publicURL };
      } else {
        rtspFields.rtsp = publicURL;
        rtspFields.rtspMain = publicURL;
      }
    }
    if (proxyRTSPMain) {
      rtspFields.proxyRTSPMain = proxyRTSPMain;
    }
  }
  const payload = {
    camera,
    manufacturer: getValue(manufacturer),
    region,
    city,
    location,
    ...rtspFields,
    cameraOfflineAlertDuration,
    emailIds,
    timezone: deviceInfo?.timeZone || null,
    isProxyStream,
    ...(editType === "wmsCamera" && { smartStreamer, backgroundRedaction }),
    ...(editType === "wmsCamera" && { cameraIds: [cameraId] }),
    sourceType: cameraType === CAMERA_TYPES.HLS ? "http" : cameraType,
  };

  if (inEditingMode && Roles.authenticateViewAndAction(WOCAMADVANCECAMERASETTINGS)) {
    const processingAttributes = {
      cloudAIProcessing,
      cloudAVProcessing,
      smartStreamerProcessing,
    };

    if (connectedToDeviceId === true) {
      processingAttributes.edgeProcessing = edgeProcessing;
    }

    const filteredProcessingAttributes = Object.fromEntries(
      Object.entries(processingAttributes).filter(
        ([, value]) => value !== undefined && value !== ""
      )
    );

    if (Object.keys(filteredProcessingAttributes).length > 0) {
      Object.assign(payload, filteredProcessingAttributes);
    }
  }
  let endPoint = `${API_BASE_URL}/camera/update/${cameraId}`;
  if (editType === "wmsCamera") {
    endPoint = `${API_BASE_URL}/standalone/camera/update`;
  }
  const response = await callApi(
    endPoint,
    {
      method: "PUT",
      body: JSON.stringify(payload),
    },
    API_CONFIG_SHOWLOADER
  );

  if (response.status !== 200) {
    throw response;
  }

  return response;
}

export async function updateNvr({ newDeviceData, deviceInfo, otherDetails }) {
  const {
    connectedCameras,
    technicalDetails: {
      cameraName,
      manufacturerName: manufacturer,
      nvrChannelId,
      port,
      ipAddress,
      nvrUsername,
      nvrPassword,
    },
    deviceDetails: {
      id: dvrId,
      dvr,
      _location: location,
      _city: city,
      _region: region,
      stream,
      dvrType = "",
    } = {},
  } = newDeviceData || {};
  const {
    cameraOfflineAlertDuration,
    emailIds,
    deviceOfflineAlertDuration,
    deviceOfflineEmailIds,
    smartStreamer,
    backgroundRedaction,
  } = otherDetails;

  const apiPayload = {
    dvr: cameraName,
    connectedCamera: connectedCameras.selectedCameras || [],
    manufacturer: getValue(manufacturer),
    region,
    city,
    location,
    port,
    host: ipAddress,
    channels: getValue(nvrChannelId),
    cameraOfflineAlertDuration,
    emailIds,
    timezone: deviceInfo?.timeZone || null,
    stream,
    username: nvrUsername,
    password: nvrPassword,
  };
  let payload = {};

  if (dvrType === "openeye") {
    payload = {
      connectedCameras: connectedCameras.selectedCameras,
      dvr,
      cameraOfflineAlertDuration,
      emailIds,
      deviceOfflineAlertDuration,
      deviceOfflineEmailIds,
      smartStreamer,
      backgroundRedaction,
    };
  } else {
    const previousSelectedCameras = connectedCameras.cameras.filter((camera) =>
      connectedCameras.selectedCameras.some((selected) => selected.channelId === camera.channelId)
    );

    payload = {
      ...apiPayload,
      connectedCamera: [...connectedCameras.newSelectedCameras, ...previousSelectedCameras],
    };
  }

  const response = await callApi(
    `${API_BASE_URL}/dvr/update/${dvrId}`,
    {
      method: "PUT",
      body: JSON.stringify(payload),
    },
    API_CONFIG_SHOWLOADER
  );

  if (response.status !== 200) {
    throw response;
  }

  return response;
}

const fetchWoConnectDetails = async ({ locationId, isStandalone }) => {
  let endPoint = `/location/${locationId}/dvrConnectCodes`;
  if (isStandalone) {
    endPoint = `/location/login-code/?location=${locationId}`;
  }
  const response = await callApi(`${API_BASE_URL}${endPoint}`, {}, API_CONFIG_NO_MANIFEST_CALL);

  return response;
};

const verifyWoConnectSetup = async (connectDeviceCode) => {
  const response = await callApi(
    `${API_BASE_URL}/verify/connect-code/${connectDeviceCode}`,
    {},
    API_CONFIG_NO_MANIFEST_CALL
  );

  return response;
};

export const woConnectRequests = {
  fetch: fetchWoConnectDetails,
  verify: verifyWoConnectSetup,
};

export async function fetchOrchidCreds(cameraId) {
  const response = await callApi(
    `${API_BASE_URL}/ipconfigure/camera/${cameraId}/iframe-details`,
    {},
    API_CONFIG_NO_MANIFEST_CALL
  );

  return response;
}
