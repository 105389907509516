import {
  HOME, LOGIN, LOGIN_WARN_ATTEMPTS, LOGOUT, PAGE404, PAGE500,
} from "../Constants";
import { store } from "../Store";
import { TOGGLE_LOADER } from "../Store/constants";
import { getHeaders } from "./getHeaders";
import { showAlert } from "./showAlert";
import updateManifest from "./updateManifest";

export const handleLoader = (state, label = "") => {
  store.dispatch({
    type: TOGGLE_LOADER,
    value: {
      label,
      value: state,
    },
  });
};

const defaultConfig = {
  showLoader: false,
  callManifest: true,
  loaderLabel: "",
};

export const showLoaderConfig = {
  showLoader: true,
  callManifest: false,
  loaderLabel: "",
};

const callApi = (url, data, config = defaultConfig) => {
  // show loader
  if (config.showLoader) handleLoader(config.showLoader, config.loaderLabel);

  // call manifest
  if (config.callManifest && store.getState().userData) updateManifest();

  return new Promise((resolve, reject) => {
    const hideLoader = () => {
      if (config.showLoader) {
        handleLoader(false, config.loaderLabel);
      }
    };

    fetch(url, { headers: getHeaders(), ...data })
      .then((res) => res.json())
      .then((res) => {
        try {
          if (res.status === 200) {
            resolve(res);
          } else if (res.status === 401) {
            window.location.href = LOGOUT;
            reject(res.message);
          } else if (res.status === 403) {
            if (window.location.pathname !== LOGIN) {
              window.location.href = HOME;
            }
            showAlert(
              res.message,
              res.details.loginAttempts === LOGIN_WARN_ATTEMPTS ? "warning" : "error"
            );
          } else if (
            res.status === 500
            && data.method
            && (data.method === "POST" || data.method === "DELETE" || data.method === "PUT")
          ) {
            showAlert(res.message, "error");
          } else if (res.status === 404) {
            window.location.href = PAGE404;
          } else if (res.status === 500 && data.method && data.method === "GET") {
            window.location.href = PAGE500;
          } else if (res.status === 422) {
            reject(res.message);
          } else {
            showAlert(res.message, "error");
          }
        } catch (e) {
          const error = new Error(`ERROR OCCURRED: ${e.message || e}`);
          reject(error);
        } finally {
          hideLoader();
        }
      })
      .catch((e) => {
        const error = new Error(`ERROR OCCURRED: ${e.message || e}`);
        reject(error);
        hideLoader();
      });
  });
};

export default callApi;
