import { Redirect, Route, Switch } from "react-router-dom";
import { Suspense } from "react";
import AccountSettingsLayout from "../Components/Common/AccountSettingsLayout";
import { ACCOUNT_SECURITY, ACCOUNT_PROFILE, PAGE404 } from "../../Constants";
import RecorderListSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/RecorderListSkeleton";
import AccountProfileContainer from "./AccountProfileContainer";
import { AccountSettingsProvider } from "../Contexts/AccountSettingsLayoutContext";
import AccountSecurityLayoutContainer from "./AccountSecurityLayoutContainer";

function AccountSettingsLayoutContainer() {
  return (
    <AccountSettingsProvider>
      <AccountSettingsLayout>
        <Switch>
          <Route exact path={ACCOUNT_PROFILE}>
            <Suspense fallback={<RecorderListSkeleton hideHeader />}>
              <AccountProfileContainer />
            </Suspense>
          </Route>

          <Route path={ACCOUNT_SECURITY}>
            <AccountSecurityLayoutContainer />
          </Route>
          <Route path="*" render={() => <Redirect to={{ pathname: PAGE404 }} />} />
        </Switch>
      </AccountSettingsLayout>
    </AccountSettingsProvider>
  );
}
export default AccountSettingsLayoutContainer;
