import IconUsers from "../../Common/Components/IconsComponent/IconUsers";
import { ACCOUNT_SECURITY, API_BASE_URL, ACCOUNT_PROFILE } from "../../Constants";
import callApi from "../../Services/callApi";
import { capitalizeInput } from "../../Services/capitalizeInput";
import { segmentTrack } from "../../Services/segment";
import { AccountSettings } from "../../Services/segmentEventDetails";
import { showAlert } from "../../Services/showAlert";
import updateManifest from "../../Services/updateManifest";
import { validateName } from "../../Services/validation";
import { profileError } from "../../Settings/Resources";
import addOrUpdateEntryInSamdock from "../../SamdockAPI/addOrUpdateEntry";
import SubmissionLoader from "../../Common/Components/Molecule/SubmissionLoader";
import Styles from "../Components/AccountProfileComponent/Style.module.scss";
import IconSecurity from "../../Common/Components/IconsComponent/IconSecurity";

export function getDefaultErrorLabels() {
  return {
    name: "",
    email: "",
  };
}

export function getAllowedFileTypes() {
  return ["image/svg+xml", "image/png", "image/jpeg", "image/gif"];
}

export function getSideBarItems() {
  return [
    {
      name: "Profile",
      link: ACCOUNT_PROFILE,
      icon: <IconUsers />,
      subMenu: [],
    },
    {
      name: "Security",
      link: ACCOUNT_SECURITY,
      icon: <IconSecurity />,
      subMenu: [],
    },
  ];
}

export function handleUserNameChange(userName, setUserName, setinputFieldsError) {
  if (!validateName(userName)) return;
  if (userName === "") {
    setinputFieldsError((prev) => ({ ...prev, name: profileError.name }));
  } else {
    setinputFieldsError((prev) => ({ ...prev, name: "" }));
  }
  const word = capitalizeInput(userName);
  setUserName(word);
}

function handleValidation(userData, userName, inputFieldsError, setinputFieldsError) {
  let isValid = true;
  let errorInForm = { ...inputFieldsError };

  const obj = {
    name: userName,
    email: userData?.user.email,
  };

  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      errorInForm = { ...errorInForm, [key]: "" };
    } else {
      errorInForm = { ...errorInForm, [key]: profileError[key] };
      isValid = false;
    }
  });
  setinputFieldsError(errorInForm);
  return isValid;
}

async function addOrEditPerson({ personName, userData }) {
  await addOrUpdateEntryInSamdock({
    isCompanyOnlyEntry: false,
    userPayload: {
      lastName: personName,
      email: userData?.user?.email,
      _id: userData?.user?.id,
      customFields: {
        role: userData?.user?.role,
        company: userData?.user?.company?.name,
      },
    },
    userId: userData?.user?.id,
    companyId: userData?.manifest?.company?.id,
    companyPayload: {
      _id: userData?.manifest?.company?.id,
      email: userData?.manifest?.company?.email,
      name: userData?.manifest?.company?.name,
      logo: userData?.manifest?.company?.logo,
      customFields: {
        industry: userData?.manifest?.company?.industries[0].industry,
      },
    },
  });
}

export async function handleUpdateEmployee(
  userData,
  userName,
  inputFieldsError,
  setinputFieldsError
) {
  segmentTrack({ title: AccountSettings.employeeProfile.updateChangesClick });
  if (!userData) return;
  const isValid = handleValidation(userData, userName, inputFieldsError, setinputFieldsError);
  if (!isValid) return;

  try {
    const employeeDetailUpdateResponse = await callApi(`${API_BASE_URL}/account/update`, {
      method: "PUT",
      body: JSON.stringify({
        name: userName,
        email: userData?.user.email,
        tz: userData?.user.tz,
      }),
    });
    if (employeeDetailUpdateResponse && employeeDetailUpdateResponse.status === 200) {
      if (userData) {
        updateManifest().then(() => {
          if (
            window?.analytics?.group
            && window?.analytics?.identify
            && window?.analytics?.track
            && userData
            && userData.user
            && userData.user.email
            && userData.user._id
            && userData.user.role
            && !userData.user.accountManager
            && userData.manifest
            && userData.manifest.company
            && userData.manifest.company.name
            && userData.manifest._id
          ) {
            window.analytics.group(userData.user._id, {
              name: userName,
              email: userData.user.email,
              username: userData.user.username,
              role: userData.user.role,
            });
            window.analytics.identify(userData.user._id, {
              companyName: userData.manifest.company.name,
              name: userName,
              email: userData.user.email,
              username: userData.user.username,
              company_id: userData.manifest._id,
            });
            segmentTrack({
              title: AccountSettings.employeeProfile.updatedProfileSuccess,
            });
          }
          showAlert("Profile has been updated", "info");
          addOrEditPerson({ personName: userName });
        });
      }
    }
  } catch (e) {
    showAlert("Something went wrong please try again", "error");
  }
}

export function renderUploadSection({ error, loading, handleFileChange }) {
  if (error) {
    showAlert(error, "error");
  }

  return (
    <div className={Styles.profile_pic_wrapper}>
      <label htmlFor="myFile">{loading ? <SubmissionLoader /> : "Update Picture"}</label>
      <input
        id="myFile"
        type="file"
        name="filename"
        accept=".svg, .png, .jpg, .jpeg, .gif"
        multiple={false}
        onChange={(e) => {
          handleFileChange(e.target.files);
        }}
      />
    </div>
  );
}

export const getFormatedbackUpCodes = (backUpCodes) => {
  if (!backUpCodes.length) return;

  const chunks = [];
  for (let i = 0; i < backUpCodes.length; i += 4) {
    chunks.push(backUpCodes.slice(i, i + 4));
  }
  // eslint-disable-next-line consistent-return
  return chunks;
};

export const getbackUpCodesAText = (backUpCodes) => backUpCodes.reduce((acc, curr) => acc.concat(curr), []).join(", ");
