import { Route, Switch } from "react-router-dom";
import Layout from "../Common/Containers/_layouts/Home";
import { ACCOUNT_SETTINGS } from "../Constants";
import AccountSettingsLayoutContainer from "./Containers";

function AccountSettingsModule() {
  return (
    <Layout>
      <Switch>
        <Route path={ACCOUNT_SETTINGS}>
          <AccountSettingsLayoutContainer />
        </Route>
      </Switch>
    </Layout>
  );
}
export default AccountSettingsModule;
