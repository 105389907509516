import React from "react";

function IconPause({ color = "#545454", size = 24 }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_28267_144655)">
        <path d="M6 19H10V5H6V19ZM14 5V19H18V5H14Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_28267_144655">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconPause;
