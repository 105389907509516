import React from "react";

const IconArrowLeft = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.41414 9.00008L10.707 1.70718L9.29282 0.292969L0.585711 9.00008L9.29282 17.7072L10.707 16.293L3.41414 9.00008Z"
        fill="black"
      />
    </svg>
  );
};

export default IconArrowLeft;
