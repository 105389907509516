import React, { lazy, Suspense } from "react";
import LoginSkeleton from "../Components/SkeletonComponent/CommonSkeleton/LoginSkeleton";

const TwoFactorBackupCodeVerificationComponent = lazy(
  () =>
    import(
      "../Components/LoginComponent/TwoFactorBackupCodeVerification/TwoFactorBackupCodeVerificationComponent"
    )
);
export default function TwoFactorBackupCodeVerification() {
  return (
    <Suspense
      fallback={<LoginSkeleton width="60vw" height={460} titleHeight={100} titleWidth="60vw" />}
    >
      <TwoFactorBackupCodeVerificationComponent />
    </Suspense>
  );
}
