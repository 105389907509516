import React from "react";

const IconPlayBackward = () => {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2H20V14H22V2C22 0.9 21.1 0 20 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H20C21.1046 16 22 15.1046 22 14H20H2V2Z"
        fill="#545454"
      />
      <path d="M14 8L8 4V12L14 8Z" fill="#545454" />
    </svg>
  );
};

export default IconPlayBackward;
