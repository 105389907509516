import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import URLService from "../../Services/URLService";

function useQuery() {
  const history = useHistory();
  const { search, pathname } = useLocation();

  const query = useMemo(() => URLService.decodeQueryParams(search), [search]);
  const updateQuery = useCallback(
    (params) => {
      const updatedURL = URLService.addOrUpdateQueryParams(pathname, { ...query, ...params });
      history.push(updatedURL);
    },
    [history, pathname, query]
  );

  return [query, updateQuery];
}

export default useQuery;
