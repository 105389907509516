import { useParams } from "react-router-dom";
import axios from "axios";
import { useCallback, useEffect } from "react";
import { API_BASE_URL_PUBLIC } from "../../../../Constants";
import useQuery from "../../../Hooks/useQuery";
import useRequest from "../../../Hooks/useRequest";
import useMakeRequest from "../../../Hooks/useMakeRequest2";
import { showAlert } from "../../../../Services/showAlert";

const fetchMetaData = async () => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    return response;
  } catch (error) {
    throw error.message;
  }
};

const fetchInsights = async ({
  reportId, token, ip, country, timeZone,
}) => {
  const res = await axios.post(`${API_BASE_URL_PUBLIC}/insights/download/${reportId}`, {
    token,
    ip,
    country,
    timeZone,
  }, { responseType: "blob" });

  if (!res.data) throw new Error("Failed to download the report");
  return res;
};

const useDownloadInsights = () => {
  const [query] = useQuery();
  const { reportId } = useParams();
  const { data: metaData, loading: metaDataLoading } = useMakeRequest({
    fetcher: fetchMetaData,
    config: {
      reportError: true,
      errorMessage: "Failed to download the report",
    },
  });

  const [_, request] = useRequest({
    request: fetchInsights,
    config: {
      reportSuccess: true,
      successMessage: "PDF report will start downloading.",
      onSuccess: (reportData) => {
        const contentDisposition = reportData.headers.get('content-disposition');
        let fileName = "report.pdf";
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+?)"$/);
          if (match && match[1]) {
            [, fileName] = match;
          }
        }
        const blob = new Blob([reportData.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      onError: () => {
        showAlert("Failed to download the report", "error");
      },
    },
  });

  useEffect(() => {
    if (!metaDataLoading && metaData) {
      request({
        reportId,
        token: (query?.token ?? "").replaceAll(" ", "+"),
        ip: metaData?.ip,
        country: metaData?.country,
        timeZone: metaData?.timezone,
      });
    }
  }, [metaDataLoading, metaData]);

  const handleRefetch = useCallback(() => {
    request({
      reportId,
      token: (query?.token ?? "").replaceAll(" ", "+"),
      ip: metaData?.ip,
      country: metaData?.country,
      timeZone: metaData?.timezone,
    });
  }, [request, metaData]);
  return handleRefetch;
};
export default useDownloadInsights;
