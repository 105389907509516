/* eslint-disable no-console */
import axios from "axios";
import { SAMDOCK_AUTH_API_URL, SAMDOCK_LOGIN_EMAIL, SAMDOCK_LOGIN_PASSWORD } from "../Constants";

const loginAndGetToken = async () => {
  const email = SAMDOCK_LOGIN_EMAIL;
  const password = SAMDOCK_LOGIN_PASSWORD;
  try {
    const response = await axios.post(`${SAMDOCK_AUTH_API_URL}/login`, {
      email,
      password,
    });
    return response.data.accessToken;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default loginAndGetToken;
