import PropTypes from "prop-types";
import React from "react";
import IconCircularLoader from "../../IconsComponent/IconLoaderCircle";
import styles from "./Styles.module.scss";

function SubmissionLoader({ loaderColor, className }) {
  return (
    <span className={`${styles.overlay} ${className}`}>
      <IconCircularLoader strokeColor={loaderColor} />
    </span>
  );
}
SubmissionLoader.propTypes = {
  loaderColor: PropTypes.string,
  className: PropTypes.string,
};
SubmissionLoader.defaultProps = {
  loaderColor: "#fff",
  className: "",
};
export default SubmissionLoader;
