import { lazy } from "react";
import PageContainer from "../../../../Common/Containers/_layouts/PageContainer";
import { PAGE_TITLES } from "../../../../Services/checkWobotDomain";

const AccountSecurity = lazy(() => import("../../../Components/AccountSecurityComponent"));

function AccountSecurityContainer() {
  return (
    <PageContainer initialPageTitle={PAGE_TITLES.accountSecurity} withLayout={false}>
      <AccountSecurity />
    </PageContainer>
  );
}

export default AccountSecurityContainer;
