function IconWarningCircle({ fill = "#A0A0A0" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill={fill}
        d="M8.791 5.625H7.208V4.042H8.79v1.583zm0 6.334H7.208v-4.75H8.79v4.75zm-.784 3.958c4.37 0 7.909-3.547 7.909-7.917 0-4.37-3.539-7.916-7.909-7.916C3.63.084.083 3.63.083 8c0 4.37 3.546 7.917 7.924 7.917zM8 1.667c3.5 0 6.334 2.834 6.334 6.333 0 3.5-2.835 6.334-6.334 6.334A6.332 6.332 0 011.666 8c0-3.499 2.834-6.333 6.333-6.333z"
      ></path>
    </svg>
  );
}

export default IconWarningCircle;
