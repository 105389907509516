import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Leaderboard, initializeConfig } from "operational-dashboard";
import {
  API_BASE_URL,
  APP_SOCKET_BASE_URL,
  DRIVE_LIST,
  DRIVE_LOCATION_LINK,
  HOME,
} from "../../../Constants";
import { SHOWREVIEWOPTION } from "../../../Constants/AccessList";
import getQueryVariable from "../../../Services/getQueryVariable";
import Roles from "../../../Services/Roles";
import { logoutAction } from "../../../Store/actions";
import "operational-dashboard/dist/index.css";

function FullLeaderboardContainer({ authToken, userData, deviceInfo, history, match, logout }) {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const {
      user: { id: userId },
      manifest: { id: companyId },
    } = userData;

    const { deviceId, name: device, deviceType, timeZone: timezone } = deviceInfo;

    initializeConfig({
      baseApiUrl: API_BASE_URL.replace("/v1", ""),
      layoutApiUrl: "/v1/drivethru/location/layout",
      shiftApiUrl: "/v1/operational/drivethru/station/shift/insight",
      locationFilterApi: "/v1/drivethru/filter?moduleType=thruputLive",
      cameraStatusApi: "/v1/camera/offline/status",
      leaderboardApi: "/v1/operational/drivethru/leaderboard",
      fullLeaderboardApi: "/v1/drivethru/leaderboard",
      dtConfigApi: "/v1/manifest",
      leaderboardTimezoneApi: "/v1/filter/timezone/get",
      enableSocket: true,
      showJourneyDataAuth: Roles.authenticateViewAndAction(SHOWREVIEWOPTION),
      socketServerUrl: APP_SOCKET_BASE_URL,
      userId,
      deviceId,
      device,
      deviceType,
      timezone,
      companyId,
      authToken,
      isExternalDashboard: true,
    });

    setIsInitialized(true);
  }, [authToken, userData, deviceInfo]);

  const onAuthFailed = () => {
    logout();
    history.replace(HOME);
  };

  const locationName = getQueryVariable("locationName");
  const { locationId } = match.params || {};
  const companyLogoUrl = userData?.manifest?.company?.logo;

  if (!isInitialized) {
    return <div style={{ background: "#0A0A0C", height: "100vh", width: "100vw" }} />;
  }

  return (
    <>
      <Helmet>
        <title>ThruPut Live | Wobot AI</title>
        <meta name="description" content="View location wise operations" />
      </Helmet>
      <Leaderboard
        companyLogoUrl={companyLogoUrl}
        locationId={locationId}
        locationName={locationName}
        goBack={() => {
          history.goBack();
        }}
        onAuthFailed={onAuthFailed}
      />
    </>
  );
}

FullLeaderboardContainer.propTypes = {
  deviceInfo: PropTypes.shape({
    deviceId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    deviceType: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
  }).isRequired,
  userData: PropTypes.shape({
    token: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    manifest: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: {
      locationId: PropTypes.string.isRequired,
    },
  }).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ location, userData, deviceInfo, authToken }) => ({
  location,
  userData,
  deviceInfo,
  authToken,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FullLeaderboardContainer));
