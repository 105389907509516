/* eslint-disable no-console */
import axios from "axios";
import { ENVIRONMENT, SAMDOCK_BASE_API_URL } from "../Constants";
import loginAndGetToken from "./loginAndGetToken";
import checkEntityExists from "./checkEntityExists";
import addPersonToCompany from "./addPersonToCompany";

const apiRequest = async ({ token, endpoint, method, payload, id }) => {
  try {
    const response = await axios({
      method,
      url: `${SAMDOCK_BASE_API_URL}/${endpoint}${id ? `/${id}` : ""}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(`Error making ${method} request to ${endpoint}:`, error);
    return null;
  }
};

const addOrUpdateEntry = async ({
  companyId,
  userId,
  companyPayload,
  userPayload,
  isCompanyOnlyEntry = false,
}) => {
  if (ENVIRONMENT !== "app") return;

  const token = await loginAndGetToken();
  const companyEndpoint = "organizations";
  const personEndpoint = "persons";

  const [companyExists, personExists] = await Promise.all([
    checkEntityExists({ id: companyId, endpoint: companyEndpoint, token }),
    isCompanyOnlyEntry
      ? Promise.resolve(true)
      : checkEntityExists({ id: userId, endpoint: personEndpoint, token }),
  ]);

  if (companyExists.status === 404) {
    await apiRequest({
      token,
      endpoint: companyEndpoint,
      method: "POST",
      payload: companyPayload,
    });
  } else if (companyExists.status === 200 && isCompanyOnlyEntry) {
    await apiRequest({
      token,
      endpoint: companyEndpoint,
      method: "PATCH",
      payload: companyPayload,
      id: companyId,
    });
  }

  if (!isCompanyOnlyEntry && personExists.status === 404) {
    await apiRequest({
      token,
      endpoint: personEndpoint,
      method: "POST",
      payload: userPayload,
    });
    await addPersonToCompany({ userId, companyId, token });
  } else if (personExists.status === 200 && !isCompanyOnlyEntry) {
    await apiRequest({
      token,
      endpoint: personEndpoint,
      method: "PATCH",
      payload: userPayload,
      id: userId,
    });
    await addPersonToCompany({ userId, companyId, token });
  }
};

export default addOrUpdateEntry;
