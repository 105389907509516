import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Layout from "../Common/Containers/_layouts/Home";
import {
  ADDEMPLOYEE,
  EMPLOYEE_DETAIL,
  EMPLOYEE_PROFILE,
  LOCATION_DETAILS,
  LOCATION_HEATMAP,
  PAGE404,
  SETTINGS,
  WOCONNECT_SETTINGS,
} from "../Constants";

import SettingsModuleWrapperSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/SettingsModuleSkeleton/SettingsModuleWrapperSkeleton";
import SettingsLayoutContainer from "./Containers";
import {
  AddEmployeeContainer,
  EmployeeDetailsContainer,
  EmployeeProfileContainer,
} from "./Containers/Employee";
import { LocationDetailsContainer, LocationHeatmapMappingContainer } from "./Containers/Location";
import WoConnectSettingsContainer from "./Containers/WoConnectSetting";

function SettingsModule() {
  return (
    <Layout>
      <Switch>
        <Route exact path={ADDEMPLOYEE}>
          <Suspense fallback={<SettingsModuleWrapperSkeleton />}>
            <AddEmployeeContainer />
          </Suspense>
        </Route>
        <Route exact path={EMPLOYEE_DETAIL}>
          <Suspense fallback={<SettingsModuleWrapperSkeleton />}>
            <EmployeeDetailsContainer />
          </Suspense>
        </Route>
        {/* <Route exact path={EMPLOYEE_PROFILE}>
          <Suspense fallback={<SettingsModuleWrapperSkeleton />}>
            <EmployeeProfileContainer />
          </Suspense>
        </Route> */}
        <Route exact path={LOCATION_HEATMAP}>
          <Suspense fallback={<SettingsModuleWrapperSkeleton />}>
            <LocationHeatmapMappingContainer />
          </Suspense>
        </Route>
        <Route exact path={LOCATION_DETAILS}>
          <Suspense fallback={<SettingsModuleWrapperSkeleton />}>
            <LocationDetailsContainer />
          </Suspense>
        </Route>

        <Route exact path={WOCONNECT_SETTINGS}>
          <Suspense fallback={<SettingsModuleWrapperSkeleton />}>
            <WoConnectSettingsContainer />
          </Suspense>
        </Route>
        <Route path={SETTINGS}>
          {/* Settings Layout Start */}
          <SettingsLayoutContainer />
          {/* Settings Layout Ends */}
        </Route>
        <Route path="*" render={() => <Redirect to={{ pathname: PAGE404 }} />} />
      </Switch>
    </Layout>
  );
}

export default SettingsModule;
