import React from "react";
import { sidebarMainOptions } from "../../../../../Constants";
import IconHelp from "../../../../Components/IconsComponent/IconHelp";
import IconKnowledge from "../../../../Components/IconsComponent/IconKnowledge";
import IconRaiseTicket from "../../../../Components/IconsComponent/IconRaiseTicket";
import IconSiteStatus from "../../../../Components/IconsComponent/IconSiteStatus";
import IconWhatNew from "../../../../Components/IconsComponent/IconWhatnew";
import RenderInsightAIToggle from "../RenderInsightAIToggle";

export default function getSidebarHeaderOptions({ pathname }) {
  return sidebarMainOptions(pathname);
}

export const middleMenuOptions = [{
  label: "",
  key: "InsightAI",
  icon: <RenderInsightAIToggle />,
}];

const getHelpLinks = ({ showRaiseTicketModal }) => {
  const helpLinks = [
    {
      label: "Help",
      key: "help",
      icon: <IconHelp />,
      isFooterMenu: true,
      subMenu: [
        {
          icon: <IconWhatNew />,
          link: "https://updates.wobot.ai/updates",
          label: "What’s new",
          key: "whats-new",
        },
        {
          icon: <IconKnowledge />,
          link: "https://help.wobot.ai/",
          label: "Knowledgebase",
          key: "knowledgeBase",
        },
        {
          icon: <IconSiteStatus />,
          key: "site-status",
          link: "https://status.wobot.ai/",
          label: "Site status",
        },
        {
          icon: <IconRaiseTicket />,
          label: "Raise a support request",
          onClick: showRaiseTicketModal,
          key: "raise-ticket",
        },
      ],
    },
  ];
  return helpLinks;
};

export function getSidebarFooterOptions({ showRaiseTicketModal }) {
  return getHelpLinks({ showRaiseTicketModal });
}
