import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from "react";
import { useLocation } from "react-router-dom";
import capitalizeFirstLetter from "../../Services/capitalizeFirstLetter";

const AccountSettingsContext = createContext();

export const twoFactorAuthDetailsInitialState = {
  code: "",
  error: false,
  backupCodes: [],
  qrUrl: "",
};

export function AccountSettingsProvider({ children }) {
  const location = useLocation();

  const [breadcrumb, setBreadCrumb] = useState([]);
  const [twoFactorAuthDetails, setTwoFactorAuthDetails] = useState(twoFactorAuthDetailsInitialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const data = location?.pathname
      .split("/")
      .slice(2)
      .map((value) => ({ name: capitalizeFirstLetter(value) }));
    setBreadCrumb(data);
  }, [location?.pathname]);

  const value = useMemo(
    () => ({
      breadcrumb,
      twoFactorAuthDetails,
      loading,
      setTwoFactorAuthDetails,
      setBreadCrumb,
      setLoading,
    }),
    [breadcrumb, twoFactorAuthDetails, loading]
  );
  return (
    <AccountSettingsContext.Provider value={value}>{children}</AccountSettingsContext.Provider>
  );
}

export function useAccountSettings() {
  const context = useContext(AccountSettingsContext);
  if (!context) {
    throw new Error("useAccountSettings must be used within a AccountSettingsLayoutContainer");
  }
  return context;
}
