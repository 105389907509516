import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import PageTitle from "../../../../Common/Components/Molecule/PageTitle";
import { accountSettings } from "../../../../Constants/MetaDetails";
import styles from "./Style.module.scss";
import { useAccountSettings } from "../../../Contexts/AccountSettingsLayoutContext";
import SidebarLinks from "../../../../Common/Components/Molecule/SidebarLinks";
import { getSideBarItems } from "../../../Utils";

function AccountSettingsLayout({ children }) {
  const { breadcrumb, setBreadCrumb } = useAccountSettings();
  const sideBarItems = useMemo(getSideBarItems, []);

  const updateBreadcrumb = useCallback(
    (item) => {
      setBreadCrumb([{ name: item.name }]);
    },
    [setBreadCrumb]
  );

  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper crumb-cursor-type">
        <PageTitle
          pageTitle={accountSettings.title}
          showSubTitle
          subTitle={accountSettings.subtitle}
          breadcrumb={[{ name: "Account Settings" }, ...breadcrumb]}
        />
        <div className={styles.body_container}>
          <div className={styles.content_sidebar}>
            <SidebarLinks handleRouteChange={updateBreadcrumb} sideBarItems={sideBarItems} />
          </div>
          <div className={styles.body_content}>
            <div className="panel-body">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
AccountSettingsLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AccountSettingsLayout;
