import { lazy } from "react";
import PageContainer from "../../../../Common/Containers/_layouts/PageContainer";
import { PAGE_TITLES } from "../../../../Services/checkWobotDomain";

const TwoFactorAuthBackupCodes = lazy(
  () => import("../../../Components/TwoFactorAuthBackupCodesCompnent")
);
function TwoFactorAuthBackupCodesContainer() {
  return (
    <PageContainer initialPageTitle={PAGE_TITLES.twoFactorBackupCodes} withLayout={false}>
      <TwoFactorAuthBackupCodes />
    </PageContainer>
  );
}

export default TwoFactorAuthBackupCodesContainer;
