import React from "react";

function InsightsDownloadIllustration() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="180" height="119" viewBox="0 0 180 119" fill="none">
      <path
        d="M152.799 118.641H29.9989C16.3347 118.641 1.86664 109.117 0.186168 91.1346
        C-2.195 65.654 18.8783 54.8741 28.4018 55.1107C25.7399 50.6743 24.5634 38.6435
        33.3675 30.8752C42.4178 22.8896 55.0204 25.2979 59.4568 28.6696C64.4256 10.391
        91.0087 -6.89266 119.26 3.47084C147.511 13.8343 149.368 39.7313 146.766 51.3843
        C157.354 52.7448 178.814 61.4639 179.95 85.4561C181.086 109.448 162.323 117.576
        152.799 118.641Z"
        fill="#E0E8FC"
      />
      <path
        d="M152.974 118.539C162.497 117.474 181.086 109.448 179.95 85.4561C178.814 61.4639 157.354 52.7448
        146.766 51.3843C149.368 39.7313 147.511 13.8343 119.26 3.47084C91.0087 -6.89266 64.4256 10.3909
        59.4568 28.6696C64.3461 16.3237 94.9452 -4.43881 120.854 16.3237C140.222 31.8446 136.648 51.8743
        135.05 58.736C146.763 59.5209 169.406 71.541 169.832 91.0332C169.919 94.998 169.527 98.3662
        168.796 101.241C166.95 106.299 159.654 112.836 148.525 114.568C144.384 115.212 115.752 115.987
        82.9875 116.873C70.2197 117.219 56.8244 117.581 44.0063 117.959L29.6409 118.539L152.974 118.539Z"
        fill="#3766E8"
        fillOpacity="0.4"
      />
      <path
        d="
          M67.9717 81.2464L84.2715 81.2464L84.2715 51.6602L96.2246 51.6602L96.2246 81.2464
          L111.981 81.2464L90.7914 110.187L67.9717 81.2464Z"
        fill="white"
        stroke="#9CB4F4"
        strokeWidth="3.61489"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default InsightsDownloadIllustration;
