import { API_BASE_URL } from "../../Constants";
import callApi from "../../Services/callApi";
import { showAlert } from "../../Services/showAlert";
import {
  atleastOneAlphabeticCharacter,
  noSpecialCharacters,
  noSpecialcharacterStart,
  validateEmail,
  validateEmpty,
} from "../../Services/validation";
import { addEmpError } from "../../Settings/Resources";
import { store } from "../../Store";
import { CameraError } from "../Resources";

class Service {
  static isLastView(viewList) {
    return viewList.length === 1;
  }

  static isCameraOffline(camera) {
    const { connectedToDeviceId = "", edgeOfflineAlert, cloudOfflineAlert } = camera || {};
    const isEdge =
      edgeOfflineAlert !== null && edgeOfflineAlert !== undefined ? edgeOfflineAlert : {};
    const isCloud = !connectedToDeviceId || Object.keys(isEdge).length === 0;

    if (isCloud) {
      // Cloud Camera
      return {
        isOffline: cloudOfflineAlert?.status === "Offline",
        offlineAlertObject: cloudOfflineAlert,
        cameraStatusData: {
          isCloud,
          cloudStatus: cloudOfflineAlert?.status,
          cloudLastActive: cloudOfflineAlert?.lastActive,
          edgeStatus: null,
          edgeLastActive: null,
        },
      };
    }
    // Edge Camera
    return {
      isOffline: edgeOfflineAlert?.status === "Offline",
      offlineAlertObject: edgeOfflineAlert,
      cameraStatusData: {
        isCloud,
        cloudStatus: cloudOfflineAlert?.status,
        cloudLastActive: cloudOfflineAlert?.lastActive,
        edgeStatus: edgeOfflineAlert?.status,
        edgeLastActive: edgeOfflineAlert?.lastActive,
      },
    };
  }

  static getCameraStatus(camera) {
    const { isOffline } = this.isCameraOffline(camera);
    if (camera?.status === "Active") {
      return isOffline ? "Offline" : "Online";
    }
    return "Muted";
  }

  /**
   * @payload
   * camerasData = [camerasIDs]
   *
   *
   * */
  static async checkAndCreateLiveView(camerasData) {
    const { userData } = store.getState();

    if (userData?.user) {
      const viewList = await callApi(
        `${API_BASE_URL}/livestream/view/get?email=${userData.user.email}&company=${userData.manifest._id}`
      );
      if (!viewList.data.length) {
        const apiData = {
          editMode: false,
          name: "SAMPLE_VIEW",
          default: true,
          cameras: camerasData,
        };
        await callApi(`${API_BASE_URL}/livestream/view/create`, {
          method: "POST",
          body: JSON.stringify(apiData),
        });
      }
    }
  }

  static getEmailFromManifest() {
    const { userData } = store.getState();
    if (userData?.user?.email) return userData.user.email;
    return "";
  }

  static copyToClipboard(text, successMessage = "Copied to clipboard", formatAlert = true) {
    const input = document.createElement("textarea");
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    showAlert(successMessage, "success", formatAlert);
  }

  // check if camera offline alert is less than 0 or in decimal
  static validateCameraOfflineAlert(num) {
    return num > 0 && num % 1 === 0;
  }

  // trim user inputs
  static trimPayload(obj) {
    try {
      const payload = {};
      for (const key in obj) {
        if (typeof obj[key] === "string") payload[key] = obj[key].trim();
        else payload[key] = obj[key];
      }
      return payload;
    } catch (e) {}
  }

  static validateOtherSettings = ({ durationKey, usersKey, duration, users }) => {
    const errorFields = {};

    if (!validateEmpty(duration)) {
      const parsedValue = Number(duration);
      if (Number.isNaN(parsedValue) || parsedValue < 5) {
        errorFields[durationKey] = CameraError.cameraOfflineAlertDuration;
      }
    }

    if (users && !validateEmpty(users)) {
      const emailList = users?.split(",");
      (emailList || []).forEach((el) => {
        if (validateEmail(el.trim())) {
          errorFields[usersKey] = addEmpError.invalidEmail;
        }
      });
    }

    return errorFields;
  };
}

export default Service;

export const validateCameraName = (value, cameraType) => {
  if (!value) {
    return CameraError.camera;
  }
  if (!noSpecialcharacterStart(value)) {
    return CameraError.invalidCamera;
  }
  if (cameraType === "openeye") {
    return null;
  }

  if (noSpecialCharacters(value)) {
    return CameraError.cameraWithNoSpecialCharacters;
  }
  if (!atleastOneAlphabeticCharacter(value)) {
    return CameraError.cameraWithOneAlphabet;
  }
  return null;
};

export const cameraTypeLabel = (cameraType) => {
  switch (cameraType) {
    case "local":
      return "WoConnect (Windows)";
    case "wms":
      return "WoConnect";
    case "openeye":
      return "OpenEye";
    case "public":
      return "-";
    case "qubo":
      return "Qubo";
    case "-":
      return "Standalone";
    default:
      return cameraType;
  }
};

export const getRTSPURLLabel = (isRTSPCameraTypeSelected, cameraType) => {
  if (isRTSPCameraTypeSelected) {
    return cameraType === "wmsCamera" ? "Local RTSP URL" : "Public RTSP URL";
  }
  return "Public HLS URL";
};

export const getCameraSubtitle = (cameraDetails, isSmartStreamerNotRunning) => {
  if (cameraDetails.cameraType === "qubo" && cameraDetails?.status === "Active") {
    return cameraDetails?.quboStatus?.value === "online"
      ? "Qubo camera is online"
      : "Qubo camera is offline";
  }
  return isSmartStreamerNotRunning ? "Smartstreamer not running" : null;
};

export const getCameraSubtitleVariant = (cameraDetails) => {
  if (cameraDetails.cameraType === "qubo") {
    return cameraDetails?.quboStatus?.value === "online" ? "Success" : "default";
  }
  return "default";
};
