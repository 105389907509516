import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Show } from "wobotui";
import { shouldShowFooter } from "../../../../Services/Service";
import { toggleNavbarAction } from "../../../../Store/actions";
import MobileMaintenanceComponent from "../../../Components/ErrorPagesComponents/MobileMaintenance";
import IconWarning from "../../../Components/IconsComponent/IconWarning";
import NotificationStrip from "../../../Components/Molecule/NotificationStrip";
import VerifyEmailStrip from "../../../Components/Molecule/VerifyEmailStrip";
import useIsMobile from "../../../Hooks/useIsMobile";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";

function Home({ children }) {
  const dispatch = useDispatch();
  const { navbar: isNavbarVisible, alertData, userData } = useSelector((state) => state);
  const showFooter = shouldShowFooter();
  const isMobile = useIsMobile();

  const handleOverlayClick = () => {
    dispatch(toggleNavbarAction());
  };
  if (isMobile) {
    return <MobileMaintenanceComponent />;
  }

  return (
    <Flex height="100vh">
      <Sidebar />
      <Flex direction="column" height="100%" width="100%" className="overflow-hidden">
        <Show when={alertData?.length > 0}>
          <NotificationStrip showNotifyIcon notifyIcon={<IconWarning />} showNotifyContent />
        </Show>
        <Show when={userData?.user?.role === "Owner" && !userData?.manifest?.isVerified}>
          <VerifyEmailStrip showNotifyContent email={userData?.user?.email} />
        </Show>
        <Header />
        {children}
        {showFooter && <Footer />}
      </Flex>
      <Show when={!isNavbarVisible}>
        <div className="overlay" onClick={handleOverlayClick} />
      </Show>
    </Flex>
  );
}

export default Home;
