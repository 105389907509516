/* eslint-disable max-len */
import React from 'react';

export default function IconSecurity() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M8 0.666656L13.4779 1.88398C13.783 1.95176 14 2.2223 14 2.53477V9.19259C14 10.53 13.3316 11.7789 12.2188 12.5208L8 15.3333L3.7812 12.5208C2.66841 11.7789 2 10.53 2 9.19259V2.53477C2 2.2223 2.21702 1.95176 2.52205 1.88398L8 0.666656ZM8 2.03252L3.33333 3.06955V9.19259C3.33333 10.0842 3.77893 10.9168 4.5208 11.4114L8 13.7309L11.4792 11.4114C12.2211 10.9168 12.6667 10.0842 12.6667 9.19259V3.06955L8 2.03252ZM8 4.66666C8.7364 4.66666 9.33333 5.26361 9.33333 5.99999C9.33333 6.49322 9.06553 6.92386 8.6674 7.15452L8.66667 9.99999H7.33333L7.33327 7.15492C6.9348 6.92439 6.66667 6.49349 6.66667 5.99999C6.66667 5.26361 7.2636 4.66666 8 4.66666Z" fill="#3766E8" />
    </svg>
  );
}
