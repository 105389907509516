import { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ACCOUNT_SECURITY, PAGE404, TWO_FACTOR_AUTH_BACKUP_CODES } from "../../../Constants";
import RecorderListSkeleton from "../../../Common/Components/SkeletonComponent/MainModulesSkeleton/RecorderListSkeleton";
import AccountSecurityContainer from "./AccountSecurityContainer";
import TwoFactorAuthBackupCodesContainer from "./TwoFactorAuthBackupCodes";

function AccountSecurityLayoutContainer() {
  return (
    <Switch>
      <Route exact path={ACCOUNT_SECURITY}>
        <Suspense fallback={<RecorderListSkeleton hideHeader />}>
          <AccountSecurityContainer />
        </Suspense>
      </Route>
      <Route exact path={TWO_FACTOR_AUTH_BACKUP_CODES}>
        <Suspense fallback={<RecorderListSkeleton hideHeader />}>
          <TwoFactorAuthBackupCodesContainer />
        </Suspense>
      </Route>
      <Route path="*" render={() => <Redirect to={{ pathname: PAGE404 }} />} />
    </Switch>
  );
}

export default AccountSecurityLayoutContainer;
