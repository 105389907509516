import moment from "moment";
import {
  API_BASE_URL, CUSTOMER_JOURNEY_LIST,
  DRIVE_LIST,
  HOME,
  TASK_DETAILS_LINK,
  TWO_FACTOR_AUTHCODE_VERIFY,
  groupedRoutesKey
} from "../Constants";
import { limitOptions } from "../Constants/limitOptions";
import { store } from "../Store";
import { addAuthToken, addRedirectUrlAfterLoginAction } from "../Store/actions";
import LoadSegmentScript from "./LoadSegmentScript";
import Roles from "./Roles";
import callApi, { handleLoader } from "./callApi";
import { showAlert } from "./showAlert";
import updateManifest from "./updateManifest";

const hideCameraFor = ["OpenEye", "Goodwill Northern Illinois"];

const checkHost = ["app.wobot.ai", "dev.wobot.ai", "staging.wobot.ai", "localhost:3000"];

const demoAccountCredentials = {
  username: "demo@wobot.ai",
  password: "Demo@123",
};

export const isPersonSuite = () => {
  const id = store.getState()?.userData?.manifest?._id;
  return id === "64535dab2f6be3b461f7cd28";
};
export const checkPersonSuite = (id) =>
  // MCD comapny id to enable walk-in
  // || id === "6474956f7d292569eb525329  (Staging ID help-test-edge2)"
  id === "64535dab2f6be3b461f7cd28";
export const hideCameraManufacturer = (...names) =>
  names.some((name) => hideCameraFor.includes(name));

export const checkCompanyTaskDetails = (company = "", task = "") =>
  company === "643edf662cda004e4fc507e9" && task === "64414fdffdf9f62e5c52ff09";

export const loginService = async (loginResponse, config) => {
  if (!config.isDemoAccount) {
    showAlert(loginResponse.message, "success", false);
  }

  // if (!loginResponse?.hideLoader) {
  handleLoader(true, "Initializing... please wait");
  // }
  store.dispatch(addAuthToken(loginResponse.data.token));

  await updateManifest();

  const { userData } = store.getState();

  if (userData?.user?._id) {
    LoadSegmentScript(true);
  }

  if (
    window?.analytics?.identify &&
    userData?.user &&
    Object.keys(userData).length &&
    userData.user.email &&
    userData.user._id &&
    userData.user.role &&
    !userData.user.accountManager &&
    userData.manifest?.company &&
    userData.manifest.company.name &&
    userData.manifest.created_at &&
    userData.manifest._id
  ) {
    await window.analytics.identify(userData.user._id, {
      name: userData.user.name,
      email: userData.user.email,
      username: userData.user.username,
      mobile: userData.user.mobile,
      role: userData.user.role,
      company: {
        id: userData.manifest._id,
        name: userData.manifest.company.name,
        email: userData.manifest.company.email,
        isDemo: userData.manifest.company.demo,
        test: userData.manifest.company.test,
        mobile: userData.user.mobile,
        signed_up_at: userData.manifest.created_at,
      },
    });
  }

  if (window?.analytics?.page) {
    await window.analytics.page();
  }
  if (!config?.next) {
    config.history.push(HOME);
  }
  handleLoader(false, "");
};

export function SlicedText({ title, maxLength }) {
  const slicedTitle = title.length <= maxLength ? title : `${title.slice(0, maxLength)}...`;

  return <span>{slicedTitle}</span>;
}

export const sliceText = (text, length = 24) =>
  text.length < length ? text : `${text.slice(0, length)}...`;

// Get Location, region, city count using region type (manifest)
export const groupBy = (array, key) =>
  array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
export const getLimitInRange = (limitQuery) => {
  if (!limitOptions.includes(limitQuery)) {
    return limitOptions?.reduce((closestLimit, limit) =>
      Math.abs(limit - limitQuery) < Math.abs(closestLimit - limitQuery) ? limit : closestLimit
    );
  }
  return limitQuery;
};

export const formatTimeTo12Hour = (time) => moment(time, "HH:mm").format("h:mm A");
class Service {
  static checkHost = (key) => {
    if (typeof key !== "string" || key === undefined) {
      throw new Error("key must be a string");
    }
    return window?.location?.host?.includes(key);
  };

  static getNumberWithCommas = (x) => x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  static isOnline() {
    return navigator.onLine;
  }

  static makeString(num, label) {
    return num > 1 ? `${num} ${label}s` : `${num} ${label}`;
  }

  /**
   * Splits a string using a specified delimiter,
   * trims each substring, and filters out empty substrings.
   *
   * @param {string} text - The input string to be split.
   * @param {("COMMA"|"PIPE"|"SEMICOLON"|"SPACE")} [delimiter='COMMA'] -
   * The delimiter used to split the input string (default is ',').
   * @returns {string[]} An array of trimmed and non-empty substrings.
   * @example
   * const commaSeparated = 'item1, item2, item3';
   * const commaSeparatedList = splitText(commaSeparated);
   */
  static splitText(text, delimiter = "COMMA") {
    const delimiters = {
      COMMA: ",",
      PIPE: "|",
      SEMICOLON: ";",
      SPACE: " ",
    };
    return (
      (text || "")
        .split(delimiters[delimiter])
        .map((item) => item.trim())
        .filter((item) => !!item) || []
    );
  }

  // config = {isDemoAccount : bool , username : "" , password : "" , history : func  }
  static async login(config) {
    try {
      // creating payload for login
      const apiPayload = {
        username: config.isDemoAccount ? demoAccountCredentials.username : config.username,
        password: config.isDemoAccount ? demoAccountCredentials.password : config.password,
      };

      const loginResponse = await callApi(
        `${API_BASE_URL}/login`,
        {
          method: "POST",
          body: JSON.stringify(apiPayload),
        },
        { showLoader: true, callManifest: false, loaderLabel: "Logging you in" }
      );
      if (loginResponse.status === 200) {   
        if(loginResponse?.code === "TWO_FACTOR_ENABLED"){ 
          sessionStorage.setItem('twoFactorAuhtoken',  loginResponse?.details.token); 
          config.history.replace(TWO_FACTOR_AUTHCODE_VERIFY)
        }else{
          if (config?.next) {
            store.dispatch(addRedirectUrlAfterLoginAction(config?.next));
          }
          await loginService(loginResponse, config);
        }
        
      }
    } catch (e) {
      showAlert(e, "error");
    }
  }
}
export const getRedirectLink = (
  taskId,
  yesterdayDateAndTime,
  todayDateAndTime,
  slug,
  location = "",
  modelType,
  camera = ""
) => {
  if (slug === "drivethru-journey-tracking") {
    return DRIVE_LIST;
  }
  if (slug === "customer-journey-tracking") {
    return CUSTOMER_JOURNEY_LIST;
  }
  return `${TASK_DETAILS_LINK}/${taskId}?modelType=${modelType}&${
    location ? `location=${location}&` : ""
  }${
    camera ? `camera=${camera}&` : ""
  }from=${yesterdayDateAndTime}&to=${todayDateAndTime}&range=Custom`;
};

export const convertDateTimeToDate = (dateTime) => {
  const date = new Date(dateTime);
  return moment(dateTime).format("MMM DD, YYYY");
};

export const shouldShowFooter = () => !checkHost.includes(window?.location?.host);

export default Service;

/**
 * An array containing keys of submenu items that represent routes with sub-routes.
 * @type {Array<string>}
 */
export const hasSubRoutes = ["settings", "wocam"];
/**
 * Checks if only a specific set of grouped routes exists in the submenu
 * after applying authentication based on user roles.
 *
 * @param {Object} el - The menu item object containing a 'subMenu' property.
 * @returns {boolean} - True if only the specified grouped routes exist, false otherwise.
 */

/**
 * Checks if the length of authenticated routes is the same as the length
 * of the grouped routes key array and if every authenticated route's key
 * is included in the grouped routes key array.
 */
export const isOnlyGroupedRoutesExistsInSubMenu = (el) => {
  const authenticatedRoutes = el?.subMenu.filter((e) => Roles?.authenticateTabs(e));
  return (
    authenticatedRoutes.length === groupedRoutesKey.length &&
    authenticatedRoutes.every((e) => groupedRoutesKey.includes(e.key))
  );
};

export function formatTimeAgo(dateString) {
  const now = moment();
  const date = moment(dateString);

  const diffInMinutes = now.diff(date, "minutes");
  const diffInHours = now.diff(date, "hours");

  if (diffInMinutes < 1) {
    return "0 mins ago";
  }
  if (diffInMinutes < 60) {
    return diffInMinutes === 1 ? "1 min ago" : `${diffInMinutes} mins ago`;
  }
  if (diffInHours < 24) {
    return diffInHours === 1 ? "1 hour ago" : `${diffInHours} hours ago`;
  }
  return date.format("MMM D");
}

const getActiveStatusForSubRoutes = (currentPathname, name) => currentPathname?.includes(name);

const getActiveStatus = (pathname, link) => pathname.indexOf(link) !== -1;

export const checkActiveTab = ({ pathname, link, name }) => {
  if (hasSubRoutes.includes(name)) {
    return getActiveStatusForSubRoutes(pathname, name);
  }
  return getActiveStatus(pathname, link);
};
export const getVisibleSubMenu = (items) => items.filter((item) => item.isVisible);
