import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "wobotui";
import IconAdd from "../../../../Common/Components/IconsComponent/IconAdd";
import ActionBlock from "../../../../Common/Components/Molecule/ActionBlock";
import PageTitle from "../../../../Common/Components/Molecule/PageTitle";
import SearchFilter from "../../../../Common/Components/Molecule/SearchFilter";
import SidebarLinks from "../../../../Common/Components/Molecule/SidebarLinks";
import { settings } from "../../../../Constants/MetaDetails";
import { useSettings } from "../../../Contexts/SettingLayoutContexts";
import styles from "./Style.module.scss";
import { getSidebarList } from "../../../Utils";

function SettingsLayout({ children }) {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { state } = useSettings();
  const {
    handleSearchQuery,
    primaryButton: { buttonText, buttonHandler, hasAccess },
    isShowSearch,
    setResetState,
  } = state;
  const searchParams = new URLSearchParams(search);
  const [searchQuery, setSearchQuery] = useState(searchParams.get("query") || "");

  const handleSearch = useCallback(
    (value) => {
      setSearchQuery(value);
      handleSearchQuery(value);
    },
    [handleSearchQuery, setSearchQuery]
  );
  const handleClearState = useCallback(() => {
    setResetState();
    setSearchQuery("");
  }, [setResetState, setSearchQuery]);

  useEffect(() => {
    const unListen = history.listen(() => {
      const previousPathname = history.location.pathname;

      if (previousPathname !== pathname) {
        setSearchQuery("");
      }
    });

    return () => unListen();
  }, [pathname]);

  const sideBarItems = useMemo(getSidebarList,[]);

  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper crumb-cursor-type">
        <PageTitle
          pageTitle={settings.title}
          showSubTitle
          subTitle={settings.subtitle}
          breadcrumb={[{ name: "Settings" }]}
        >
          <ActionBlock showActionList={false} showActionBtn>
            {buttonText && hasAccess ? (
              <li className="list-inline-item ">
                <Button type="button" onClick={() => buttonHandler()} iconLeft={<IconAdd />}>
                  {buttonText}
                </Button>
              </li>
            ) : null}
            {isShowSearch && handleSearchQuery ? (
              <li className="list-inline-item">
                <SearchFilter value={searchQuery} handleUpdateValue={handleSearch} />
              </li>
            ) : null}
          </ActionBlock>
        </PageTitle>
        <div className={styles.body_container}>
          <div className={styles.content_sidebar}>
            <SidebarLinks handleRouteChange={handleClearState} sideBarItems={sideBarItems} />
          </div>
          <div className={styles.body_content}>
            <div className="panel-body">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
SettingsLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default SettingsLayout;
